import { useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import {
  flyToZoom,
  maxSampleLimit,
  maxZoom,
  minSampleLimit,
  minZoom,
  options,
  zoomRadius,
} from '../constants';
import { getData, setSettings } from '../redux';
import L from 'leaflet';
import hexbinLayer from '../services/hexbin-layer';
import * as d3 from 'd3';
import { useState } from 'react';
import pointer from '../icons/spot-icon.svg';

/**
 * Map container. As map component is not rendered by react - it is necesary to use refs to deal with updated properties.
 *
 * @module
 *
 * @property {Boolean} setPopoverIsShown ../components/map/map -> sets popoverIsShown value
 * @property {Function} changeLoaderClassList ../components/map/map -> changes Time Counter loader to run/full depending on passed flag value
 * @property {Function} setValue ../components/map/map -> sets value value
 * @property {Integer} value ../components/map/map -> a value counting +1 when the user interacts with map/minimap, used for debouncing: when it stops counting the debouncing timer starts, when it counts the debouncing timer is reset
 * @property {Integer} debouncedValue ../components/map/map -> this value changes to value after debouncing timeout
 * @property {Boolean} popoverIsShown ../components/map/map -> [is modal window on right click on map shown] ? true : false
 * @property {Function} setFullFooterIsShown ../components/map/map -> sets fullFooterIsShown value
 * @property {Function} setShowSearchResults ../components/map/map -> sets showSearchResults value
 *
 * @property {Array.<Array.<Number>>} data Redux/data -> initial data loaded from endpoint
 * @property {Object[]} geoJson Redux/data -> initial data converted to geoJson format
 * @property {Boolean} loading Redux/data -> [is data loading] ? true : false
 *
 * @property {Integer} currentZoom Redux/settings -> current zoom of the map value
 * @property {Integer} sampleLimit not used anymore
 * @property {Boolean} mapIsReady Redux/settings -> [is map loaded and ready] ? true : false
 * @property {Integer} currentTn Redux/settings -> current value of Tn
 * @property {Integer} currentTr Redux/settings -> current value of Tr
 * @property {Integer} currentVs30 Redux/settings -> current value of Vs30
 * @property {Integer[]} boundingBox Redux/settings -> min and max latitude and longitude of the current active map area
 * @property {Number[]} flyToLatLon Redux/settings -> latitude and longitude of the map point to fly to
 * @property {Number[]} chosenLonLat Redux/settings -> longitude and latitude of a click on the map
 * @property {Integer[]} dataRange Redux/settings -> min and max value of 2 types of data chosen on the intensity scale
 * @property {Boolean} PointLayerIsOn Redux/settings -> [is points layer shown on the map] ? true : false
 * @property {Number[]} boundingBoxFromMiniMap Redux/settings -> bounding box from mini map in geo coordinates to put map into
 * @property {Integer} flyToZoomInit Redux/settings/flyToZoom -> the zoom value to apply when fly to is applied
 *
 * @property {Integer} flyToZoom ../constants -> the level of zoom to apply flyTo function
 * @property {Integer} maxSampleLimit ../constants -> not used anymore
 * @property {Integer} maxZoom ../constants -> max allowed zoom level
 * @property {Integer} minSampleLimit ../constants -> not used anymore
 * @property {Integer} minZoom ../constants -> min allowed zoom level
 * @property {Object} options ../constants -> hexbin options
 * @property {Object} zoomRadius ../constants -> zoom levels and corresponding hexbin radiuses: key - zoom, prop - radius
 *
 * @property {ref} dataRangeRef ref to dataRange
 *
 * @property {ref} flyToZoomInitRef ref to flyToZoomInit
 * @property {ref} boundingBoxFromMiniMapRef ref to boundingBoxFromMiniMap
 * @property {ref} valueRef ref to value
 * @property {ref} PointLayerIsOnRef ref to PointLayerIsOn
 * @property {ref} loadingRef ref to loading
 * @property {ref} dataRef ref to data
 * @property {ref} geoJsonRef ref to geoJson
 * @property {ref} boundingBoxRef ref to boundingBox
 *
 * @property {ref} mapIsReadyRef ref to mapIsReady
 * @property {ref} sampleLimitRef ref to sampleLimit
 * @property {ref} zoomRef ref to currentZoom
 * @property {ref} currentTnRef ref to currentTn
 * @property {ref} currentTrRef ref to currentTr
 * @property {ref} currentVs30Ref ref to currentVs30
 * @property {ref} flyToLatLonRef ref to flyToLatLon
 * @property {ref} chosenLonLatRef ref to chosenLonLat
 * @property {ref} popoverIsShownRef ref to popoverIsShown
 *
 * @property {Object} hexBins not used anymore
 * @property {ref} hexBinsRef not used anymore
 *
 * @property {Object} map a leaflet map instance referenced through useMap() hook
 * @property {ref} prop map length in geo coords
 * @property {ref} radhex current hexbin radius
 * @property {ref} hexLayerRef current hexbin layer instance
 *
 * @property {Boolean} wasHexClicked [user clicked inside hexbin layer] ? true : false
 *
 * @property {Object} geoJsonLayer geoJsonLayer instance
 * @property {ref} geoJsonLayerRef ref to geoJsonLayer
 *
 * @property {ref} markerRef a map marker instance
 */
const Map = ({
  setPopoverIsShown,
  changeLoaderClassList,
  setValue,
  value,
  debouncedValue,
  popoverIsShown,
  setFullFooterIsShown,
  setShowSearchResults,
}) => {
  const dispatch = useDispatch();
  const { data, geoJson, loading } = useSelector((state) => state.data);
  const {
    currentZoom,
    sampleLimit,
    mapIsReady,
    currentTn,
    currentTr,
    currentVs30,
    boundingBox,
    flyToLatLon,
    chosenLonLat,
    dataRange,
    PointLayerIsOn,
    boundingBoxFromMiniMap,
  } = useSelector((state) => state.settings);

  const flyToZoomInit = useSelector((state) => state.settings.flyToZoom);

  const dataRangeRef = useRef();
  dataRangeRef.current = dataRange;

  const flyToZoomInitRef = useRef();
  flyToZoomInitRef.current = flyToZoomInit;
  const boundingBoxFromMiniMapRef = useRef();
  boundingBoxFromMiniMapRef.current = boundingBoxFromMiniMap;
  const valueRef = useRef();
  valueRef.current = value;
  const PointLayerIsOnRef = useRef();
  PointLayerIsOnRef.current = PointLayerIsOn;
  const loadingRef = useRef();
  loadingRef.current = loading;
  const dataRef = useRef();
  dataRef.current = data;
  const geoJsonRef = useRef();
  geoJsonRef.current = geoJson;
  const boundingBoxRef = useRef();
  boundingBoxRef.current = boundingBox;

  const mapIsReadyRef = useRef();
  mapIsReadyRef.current = mapIsReady;
  const sampleLimitRef = useRef();
  sampleLimitRef.current = sampleLimit;
  const zoomRef = useRef();
  zoomRef.current = currentZoom;
  const currentTnRef = useRef();
  currentTnRef.current = currentTn;
  const currentTrRef = useRef();
  currentTrRef.current = currentTr;
  const currentVs30Ref = useRef();
  currentVs30Ref.current = currentVs30;
  const flyToLatLonRef = useRef();
  flyToLatLonRef.current = flyToLatLon;
  const chosenLonLatRef = useRef();
  chosenLonLatRef.current = chosenLonLat;
  const popoverIsShownRef = useRef();
  popoverIsShownRef.current = popoverIsShown;

  const [hexBins, setHexBins] = useState([]);
  const hexBinsRef = useRef();
  hexBinsRef.current = hexBins;

  const map = useMap();
  const prop = useRef(null);
  const radhex = useRef(null);
  const hexLayerRef = useRef(null);

  const wasHexClicked = useRef(false);

  const geoJsonLayer = L.geoJSON([], {
    style: function (feature) {
      return { color: feature.properties.color };
    },
    pointToLayer: function (feature, latlang) {
      return L.circleMarker(latlang, {
        radius: feature.properties.radius,
        color: feature.properties.color,
        stroke: false,
        fillColor: feature.properties.color,
        opacity: 1,
        fillOpacity: 0.9,
      });
    },
  });

  const geoJsonLayerRef = useRef(geoJsonLayer);

  const markerRef = useRef();

  /**
   * Triggers initial data loading in redux store.
   * @function loadData
   */
  const loadData = () => {
    dispatch(getData());
  };

  /**
   * Triggers flyTo function when coordinates to fly to were changed and cleares those coordinates in Redux store.
   * @function useEffect[flyToLatLonRef.current]
   */
  useEffect(() => {
    if (flyToLatLonRef.current.length) {
      map.flyTo(flyToLatLonRef.current, flyToZoomInitRef.current);
      dispatch(setSettings({ flyToLatLon: [] }));
      dispatch(setSettings({ flyToZoom: flyToZoom }));
    }
  }, [flyToLatLonRef.current]);

  /**
   * Triggers moving map to a new position when minimap frame was moved by user.
   * @function useEffect[boundingBoxFromMiniMapRef.current]
   */
  useEffect(() => {
    if (boundingBoxFromMiniMapRef.current.length) {
      map.fitBounds(boundingBoxFromMiniMapRef.current);
      dispatch(setSettings({ boundingBoxFromMiniMap: [] }));
    }
  }, [boundingBoxFromMiniMapRef.current]);

  /**
   * Add or hides geojson layer.
   * @function useEffect[PointLayerIsOnRef.current]
   */
  useEffect(() => {
    if (PointLayerIsOnRef.current) {
      geoJsonLayerRef.current.addTo(map);
    } else {
      geoJsonLayerRef.current.remove();
    }
  }, [PointLayerIsOnRef.current]);

  /**
   * When map instance was changed resets props in Redux store and event handlers of the map.
   * @function useEffect[map]
   */
  useEffect(() => {
    const coords = map.getBounds();
    const lngMin = coords._southWest.lng;
    const lngMax = coords._northEast.lng;
    const latMin = coords._southWest.lat;
    const latMax = coords._northEast.lat;

    dispatch(
      setSettings({
        boundingBox: [lngMin, lngMax, latMin, latMax],
      })
    );

    map.whenReady(() => {
      document.getElementsByClassName('timecounter-item')[0].classList.add('firstload');
      dispatch(setSettings({ mapCenter: map.getCenter() }));
      loadData();
      prop.current = map.getBounds().getEast() - map.getBounds().getWest();
      radhex.current = 5;
    });

    map.on('zoomend', (e) => {
      setPopoverIsShown(false);
      changeLoaderClassList(true);
      onMapMove();

      let newarea = map.getBounds().getEast() - map.getBounds().getWest();
      let newRadius = radhex.current * (prop.current / newarea);

      if (map.getZoom() - zoomRef.current > 0) {
        hexLayerRef.current.radius(newRadius);
        hexLayerRef.current.radiusRange([newRadius, newRadius]);
      } else if (map.getZoom() - zoomRef.current < 0) {
        hexLayerRef.current.radius(newRadius);
        hexLayerRef.current.radiusRange([newRadius, newRadius]);
      }

      dispatch(setSettings({ currentZoom: map.getZoom() }));
    });

    map.on('moveend', () => {
      dispatch(setSettings({ mapCenter: map.getCenter() }));
      setPopoverIsShown(false);
      changeLoaderClassList(true);
      onMapMove();
    });

    map.on('move', () => {
      // setValue(valueRef.current === 1 ? 2 : 1);
      setValue(valueRef.current + 1);
      // console.log(valueRef.current);
    });

    map.on('zoom', () => {
      // setValue(valueRef.current === 1 ? 2 : 1);
      setValue(valueRef.current + 1);
      // console.log(valueRef.current);

      setPopoverIsShown(false);
      changeLoaderClassList(false);
      setShowSearchResults(false);
    });

    map.on('zoomstart', () => {
      setPopoverIsShown(false);
      setFullFooterIsShown(false);
      setShowSearchResults(false);
      changeLoaderClassList(false);
    });

    map.on('movestart', () => {
      setPopoverIsShown(false);
      setFullFooterIsShown(false);
      setShowSearchResults(false);
      changeLoaderClassList(false);
    });

    map.on('click', (e) => {
      setPopoverIsShown(false);
      setFullFooterIsShown(false);
    });

    map.on('contextmenu', (e) => {
      if (wasHexClicked.current) {
        setFullFooterIsShown(false);
        getGeoCoordsOnClick(e);
        wasHexClicked.current = false;
      }
    });

    //redefine zoom position
    L.control
      .zoom({
        position: 'topright',
      })
      .addTo(map);
  }, [map]);

  /**
   * Not used anymore.
   * @function setNewSampleLimit
   */
  const setNewSampleLimit = () => {
    const currentZoom = map.getZoom();

    dispatch(setSettings({ currentZoom }));

    if (currentZoom <= minZoom) {
      dispatch(setSettings({ sampleLimit: minSampleLimit }));
      return;
    }

    if (currentZoom >= maxZoom) {
      dispatch(setSettings({ sampleLimit: maxSampleLimit }));
      return;
    }

    let currentSampleLimit = Math.floor(
      sampleLimitRef.current +
        (((currentZoom - zoomRef.current) * (maxZoom - minZoom)) /
          (maxSampleLimit - minSampleLimit)) *
          100
    );

    currentSampleLimit =
      currentSampleLimit > 100
        ? 100
        : currentSampleLimit < minSampleLimit
        ? minSampleLimit
        : currentSampleLimit;

    dispatch(setSettings({ sampleLimit: currentSampleLimit }));
  };

  /**
   * Removes flag from map footer.
   * @function useEffect[]
   */
  useEffect(() => {
    d3.selectAll('.leaflet-attribution-flag').remove();
  }, []);

  /**
   * Create hexbin layer setting its properties and event handlers.
   * @function createHexLayer
   */
  const createHexLayer = () => {
    if (dataRef.current.length > 0 && !loadingRef.current) {
      dispatch(setSettings({ mapIsReady: true }));
      let currentOptions = options;
      let zoom = Math.round(map.getZoom());
      currentOptions.radius = zoomRadius[zoom];
      currentOptions.radiusRange = [zoomRadius[zoom], zoomRadius[zoom]];
      radhex.current = zoomRadius[zoom];
      prop.current = map.getBounds().getEast() - map.getBounds().getWest();

      hexLayerRef.current = hexbinLayer(currentOptions);
      hexLayerRef.current
        .colorValue((data, i) => {
          let users_sum = data.reduce(function (acc, obj) {
            return acc + obj['o'][2];
          }, 0);
          return users_sum / data.length;
        })
        .hoverHandler(
          L.HexbinHoverHandler.compound({
            handlers: [
              L.HexbinHoverHandler.resizeFill(),
              L.HexbinHoverHandler.tooltip({
                var:
                  currentTnRef.current === 0
                    ? 'PGA'
                    : currentTnRef.current === -1
                    ? 'PGV'
                    : 'Sa',
                units: currentTnRef.current === -1 ? ' [cm/s]' : ' g',
              }),
            ],
          })
        )
        .dispatch()
        .on('mouseover', function (d, i) {
          document.getElementsByClassName('leaflet-container')[0].style.cursor =
            'pointer';
        })
        .on('mouseout', function (d, i) {
          document.getElementsByClassName('leaflet-container')[0].style.cursor = '';
        })
        .on('click', function (d, i) {
          const meanVal = (
            i.reduce(function (acc, obj) {
              return acc + obj['o'][2];
            }, 0) / i.length
          ).toFixed(5);

          dispatch(setSettings({ pickedMapValue: meanVal }));
        })
        .on('contextmenu', function (d, i) {
          wasHexClicked.current = true;
          const meanVal = (
            i.reduce(function (acc, obj) {
              return acc + obj['o'][2];
            }, 0) / i.length
          ).toFixed(5);

          dispatch(setSettings({ pickedMapValue: meanVal }));
          setPopoverIsShown(true);
        });

      // if (dataRef.current._data?.length) {
      //   hexLayerRef.current.addTo(map);
      // }

      hexLayerRef.current.colorScaleExtent(
        currentTnRef.current === -1
          ? [dataRange[2], dataRange[3]]
          : [dataRange[0], dataRange[1]]
      );
      geoJsonLayerRef.current.clearLayers();
      geoJsonLayerRef.current.addData(geoJsonRef.current);

      hexLayerRef.current.data(dataRef.current);
      hexLayerRef.current.addTo(map);
    }
  };

  /**
   * When data was changed changes color scheme of hexbin layer and triggers its updating - it is refreshed if data sources changes.
   * @function useEffect[dataRangeRef.current]
   */
  useEffect(() => {
    if (hexLayerRef.current) {
      hexLayerRef.current.colorScaleExtent(
        currentTnRef.current === -1
          ? [dataRange[2], dataRange[3]]
          : [dataRange[0], dataRange[1]]
      );
      hexLayerRef.current.data(dataRef.current);
    }
  }, [dataRangeRef.current]);

  /**
   * When loading finishes hides modal window (as data is updated), removes hexbin layer and creates it.
   * @function useEffect[loadingRef.current]
   */
  useEffect(() => {
    if (!loadingRef.current) {
      setPopoverIsShown(false);

      if (hexLayerRef.current) {
        d3.selectAll('g.hexbin-container').remove();
        d3.selectAll('.hexbin-tooltip').remove();
        map.removeLayer(hexLayerRef.current);
        hexLayerRef.current = null;
      }
      createHexLayer();
    }
  }, [loadingRef.current]);

  /**
   * Sets coordinates of event on the map, removes current marker and sets new.
   * @function getGeoCoordsOnClick
   * @param {Object} d event generated by user interaction with map
   */
  const getGeoCoordsOnClick = (d) => {
    const xMapContainer = d.layerPoint.x;
    const yMapContainer = d.layerPoint.y;

    const lngClicked = d.latlng.lng;
    const latClicked = d.latlng.lat;

    document.getElementsByClassName('hexbin-tooltip')[0].style.visibility = 'hidden';

    dispatch(
      setSettings({
        chosenLonLat: [lngClicked, latClicked],
        chosenXY: [xMapContainer, yMapContainer],
      })
    );

    if (markerRef.current) {
      map.removeLayer(markerRef.current);
    }
    var myIcon = L.icon({
      iconUrl: pointer,
      iconAnchor: [22.545, 40.7],
    });
    markerRef.current = new L.Marker([latClicked, lngClicked], { icon: myIcon });
    markerRef.current.addTo(map).on('click', () => {
      // if (popoverIsShownRef.current) {
      //   map.removeLayer(markerRef.current);
      // }
      // else {
      //   setFullFooterIsShown(false);
      // }
      setPopoverIsShown(false);
    });
  };

  /**
   * When closing modal window removes current marker from map.
   * @function useEffect[popoverIsShownRef.current]
   */
  useEffect(() => {
    if (markerRef.current && !popoverIsShownRef.current) {
      map.removeLayer(markerRef.current);
    }
  }, [popoverIsShownRef.current]);

  /**
   * When user moves/zooms map increses debouncing value and sets new bounding geo coordinates in Redux/settings.
   * @function onMapMove
   */
  const onMapMove = () => {
    // setValue(valueRef.current === 1 ? 2 : 1);
    setValue(valueRef.current + 1);
    const coords = map.getBounds();
    const lngMin = coords._southWest.lng;
    const lngMax = coords._northEast.lng;
    const latMin = coords._southWest.lat;
    const latMax = coords._northEast.lat;
    setNewSampleLimit();
    dispatch(
      setSettings({
        boundingBox: [lngMin, lngMax, latMin, latMax],
      })
    );
  };

  /**
   * Not sure if it really affects something...
   * @function useEffect[]
   */
  useEffect(() => {
    if (debouncedValue) {
      loadData();
    }
  }, []);

  return null;
};

export default Map;
