import { combineReducers } from 'redux';
import dataReducer from './data/reducer';
import infoReducer from './info/reducer';
import settingsReducer from './settings/reducer';
import errorReducer from './error/reducer';

export default combineReducers({
  data: dataReducer,
  info: infoReducer,
  settings: settingsReducer,
  errors: errorReducer,
});
