import { GET_DATA, FILTER_DATA, DATA_LOADING, DATA_LOADED, SET_GEOJSON } from './types';
import { returnErrors } from '../error/actions';
import Lodash from 'lodash';
import axios from 'axios';
import { DATA_ENDPOINT } from '../../constants';
import Papa from 'papaparse';
import store from '../store';
import { transformDataToGeojson } from '../../helpers';

/**
 * Stores initial data.
 * @module
 *
 * @property {String} DATA_ENDPOINT ../../constants -> initial data end point url
 */

/**
 * Not used anymore
 * @function filterData
 * @param {Object[]} data filtered data
 */
export const filterData = (data) => (dispatch) => {
  dispatch(setDataLoading());

  dispatch({
    type: FILTER_DATA,
    payload: { data },
  });
};

/**
 * Loads initial data, processed it and sets Redux/data/data and Redux/data/geoJson
 * @function getData
 */
export const getData = () => (dispatch) => {
  const _ = Lodash;

  const { currentTn, currentTr, currentVs30, sampleLimit, boundingBox } =
    store.getState().settings;

  const url = `${DATA_ENDPOINT}?sample_size=${sampleLimit}&vs30=${currentVs30}&tn=${currentTn}&tr=${currentTr}&minlon=${boundingBox[0]}&maxlon=${boundingBox[1]}&minlat=${boundingBox[2]}&maxlat=${boundingBox[3]}`;
  dispatch(setDataLoading());

  axios.interceptors.response.use(undefined, (err) => {
    const { config, message } = err;
    if (!config || !config.retry) {
      return Promise.reject(err);
    }
    // retry while Network timeout or Network Error
    if (!(message.includes('timeout') || message.includes('Network Error'))) {
      return Promise.reject(err);
    }
    config.retry -= 1;
    const delayRetryRequest = new Promise((resolve) => {
      setTimeout(() => {
        console.log('retry the request', config.url);
        resolve();
      }, config.retryDelay || 1000);
    });
    return delayRetryRequest.then(() => axios(config));
  });

  axios
    .get(url, { retry: 3, retryDelay: 1000 })
    .then((res) => {
      Papa.parse(res.data.file, {
        download: true,
        complete: (result) => {
          result.data.shift();
          result.data.pop();
          const nums = result.data.map((el) => [+el[0], +el[1], +el[2]]);

          const geoJson = transformDataToGeojson(nums);

          dispatch({
            type: GET_DATA,
            payload: nums,
          });
          dispatch({
            type: SET_GEOJSON,
            payload: geoJson,
          });
        },
        error: (err) => {
          console.log(err);
          dispatch(returnErrors('Error accessing csv file', ''));
          dispatch({
            type: DATA_LOADED,
          });
        },
      });
    })
    .catch((e) => {
      console.log('ERROR: ', e);
      dispatch(returnErrors(e.message, e.code));
      dispatch({
        type: DATA_LOADED,
      });
    });
};

/**
 * Sets Redux/data/loading to true
 * @function setDataLoading
 */
export const setDataLoading = () => {
  return {
    type: DATA_LOADING,
  };
};
