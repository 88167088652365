import { TRs, mapPointColor, mapPointRadius, pixeslToRegionId } from '../constants';

/**
 * Functions that dont belong to any components.
 *
 * @module
 */

/**
 * Transform initial data to geojson format.
 * @function transformDataToGeojson
 * @param {Object[]} data initial data from endpoint to process
 * @returns data in geojson format
 */
export const transformDataToGeojson = (data) => {
  let res = [
    {
      type: 'FeatureCollection',
      features: [],
    },
  ];

  data.forEach((el) => {
    res[0].features.push({
      type: 'Feature',
      properties: {
        radius: mapPointRadius,
        color: mapPointColor,
        value: el[2],
      },
      geometry: {
        type: 'Point',
        coordinates: [el[0], el[1]],
      },
    });
  });

  return res;
};

/**
 * Transform initial data to be put into table in report.
 * @function transformTablaData
 * @param {Object[]} data initial data from endpoint to process
 * @returns data for table in report
 */
export const transformTablaData = (data) => {
  let response = {};
  TRs.forEach(function (tr) {
    let trdata = data.filter((d) => d.it_haz__seismic_tr_i === tr);
    var t = {};
    for (let data of trdata) {
      if (data.it_haz__seismic_tn_i === 0) {
        t[data.it_haz__seismic_tn_i] = data.it_haz__seismic_pga_n;
        t[-1] = data.it_haz__seismic_pgv_n; //index -1 para quedar en la primera columna de la tabla
      } else {
        t[data.it_haz__seismic_tn_i] = data.it_haz__seismic_sa_n;
      }
    }
    response[tr] = t;
  });

  return response;
};

/**
 * Transform initial data to geojson format.
 * @function transformChartsData
 * @param {Object[]} data initial data from endpoint to process
 * @returns data for two charts
 */
export const transformChartsData = (data) => {
  let response = {};

  let trs_set = new Set();
  let tns_set = new Set();

  data.forEach((row) => {
    trs_set.add(parseFloat(row.it_haz__seismic_tr_i));
    tns_set.add(parseFloat(row.it_haz__seismic_tn_i));
  });

  let trs = Array.from(trs_set);
  let tns = Array.from(tns_set);

  trs.sort(function (a, b) {
    return a - b;
  });
  tns.sort(function (a, b) {
    return a - b;
  });

  trs.forEach(function (tr) {
    let trdata = data.filter((d) => d.it_haz__seismic_tr_i === tr);
    var t = {};
    for (let data of trdata) {
      if (data.it_haz__seismic_tn_i === 0) {
        t[data.it_haz__seismic_tn_i] = data.it_haz__seismic_pga_n;
      } else {
        t[data.it_haz__seismic_tn_i] = data.it_haz__seismic_sa_n;
      }
    }
    response[tr] = t;
  });

  //data para grafico 1
  let g1 = [];
  for (let ts of tns) {
    let trace = {};
    let col = [];
    for (let sa of Object.keys(response)) {
      col.push(response[sa][ts]);
    }
    trace = {
      x: col,
      y: [1 / 100, 1 / 250, 1 / 500, 1 / 1000, 1 / 2500, 1 / 5000, 1 / 10000],
      type: 'scatter',
      mode: 'scatter',
      line: { shape: 'rect' },
      name: 'T=' + ts + ' s',
    };
    g1.push(trace);
  }

  //data para grafico 2
  let g2 = [];
  let trace = {};
  for (let key of trs) {
    let row = [];
    for (let sa of Object.keys(response[key]).sort(function (a, b) {
      return a - b;
    })) {
      row.push(response[key][sa]);
    }
    trace = {
      x: tns,
      y: row,
      type: 'scatter',
      line: { shape: 'rect' },

      mode: 'scatter',
      name: 'Tr=' + key + ' años',
    };
    g2.push(trace);
  }

  return { g1, g2 };
};

/**
 * It is not used now, it was a try to color chile mini map svg by regions depending on vertical coord.
 * @function getElementId
 * @param {Integer} coord current vertical coordinate of a mini map box in pixels
 * @returns the key / keys of regions to color
 */
export const getElementId = (coord) => {
  const activeRegions = [];

  for (const [key, value] of Object.entries(pixeslToRegionId)) {
    if (coord >= value[0] && coord <= value[1]) {
      activeRegions.push(key);

      document
        .getElementById('miniMapSvg')
        .contentDocument.getElementById(key).style.fill = 'black';
    }
  }

  return activeRegions;
};

/**
 * It is not used now, it was a try to color chile mini map svg by regions depending on vertical coord. This one sets regions color to default.
 * @function getElementId
 * @param {Integer[]} activeRegions array of regions not to color, the rest will be colored to default
 */
export const elementsDefaultFill = (activeRegions = []) => {
  if (document.getElementById('miniMapSvg')) {
    for (const [key, value] of Object.entries(pixeslToRegionId)) {
      if (!activeRegions.includes(key)) {
        document
          .getElementById('miniMapSvg')
          .contentDocument.getElementById(key).style.fill = '#BFC3CD';
      }
    }
  }
};
