import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-lodash-debounce';
import {
  CurrentValuesComponent,
  ErrorComponent,
  MapComponent,
  LegendComponent,
} from '../components';
import {
  dataLoadDelayOnMapMove,
  maxZoom,
  minZoom,
  pixeslToRegionId,
  initialMapCoords,
  activeMinimapPosition,
  initMiniMapFramePosition,
} from '../constants';
import {
  MiniMapContainer,
  NavMenuContainer,
  PopoverContainer,
  ReportContainer,
  TimecounterContainer,
  FooterContainer,
} from '../containers';
import { elementsDefaultFill, getElementId } from '../helpers';
import { setDataRange, setSettings } from '../redux';

/**
 * This is a home page file which holds all the containers in this one page app.
 *
 * @module
 *
 * @property {Integer} value a value counting +1 when the user interacts with map/minimap, used for debouncing: when it stops counting the debouncing timer starts, when it counts the debouncing timer is reset
 * @property {Integer} debouncedValue this value changes to value after debouncing timeout
 *
 * @property {Boolean} reportIsShown [is report shown] ? true : false
 * @property {Boolean} miniMapIsShown [is mini map shown] ? true : false
 * @property {Boolean} popoverIsShown [is modal window on right click on map shown] ? true : false
 * @property {Boolean} fullFooterIsShown [is full footer shown] ? true : false
 * @property {Integer} fullFooterTabIndex full footer tab index
 * @property {Boolean} legendIsShown [is intensity scale shown] ? true : false
 * @property {String} tnLabel current Tn value label
 * @property {String} trLabel current Tr value label
 * @property {String} vs30Label current Vs30 value label
 * @property {Boolean} showSearchResults [are search results shown] ? true : false
 *
 * @property {Integer} dataLoadDelayOnMapMove ../constants -> debouncing time before data loading starts
 * @property {Integer} maxZoom ../constants -> max allowed zoom level
 * @property {Integer} minZoom ../constants -> min allowed zoom level
 * @property {Object} pixeslToRegionId ../constants -> not used anymore
 * @property {Number[]} initialMapCoords ../constants -> initial map position geo coords
 * @property {Integer} activeMinimapPosition ../constants -> corrects the position of the background image of minimap frame
 * @property {Integer} initMiniMapFramePosition ../constants -> corrects the position of mini map frame
 *
 * @property {Number[]} chosenLonLat Redux/settings -> longitude and latitude of a click on the map
 * @property {Integer} currentTn Redux/settings -> current value of Tn
 * @property {Integer} currentTr Redux/settings -> current value of Tr
 * @property {Integer} currentVs30 Redux/settings -> current value of Vs30
 * @property {Integer} currentZoom Redux/settings -> current zoom of the map value
 * @property {Object[]} filters Redux/settings -> Tn/Tr/Vs30 filters data
 * @property {Boolean} mapIsReady Redux/settings -> [is map loaded and ready] ? true : false
 *
 * @returns {React.ReactElement} ErrorComponent, TimecounterContainer, NavMenuContainer, MapComponent, ReportContainer, PopoverContainer, CurrentValuesComponent, LegendComponent, FooterContainer
 */
const Home = () => {
  const [value, setValue] = useState(0);
  const debouncedValue = useDebounce(value, dataLoadDelayOnMapMove);

  const { loading } = useSelector((state) => state.data);

  const [reportIsShown, setReportIsShown] = useState(false);
  const [miniMapIsShown, setMiniMapIsShown] = useState(false);
  const [popoverIsShown, setPopoverIsShown] = useState(false);
  const [fullFooterIsShown, setFullFooterIsShown] = useState(false);
  const [fullFooterTabIndex, setFullFooterTabIndex] = useState(0);
  const [legendIsShown, setLegendIsShown] = useState(true);
  const [showSearchResults, setShowSearchResults] = useState(false);

  // Look for description of variables below in
  // ../redux/settings/reducer -> initialState
  const {
    chosenLonLat,
    currentTn,
    currentTr,
    currentVs30,
    currentZoom,
    filters,
    mapIsReady,
  } = useSelector((state) => state.settings);

  const dispatch = useDispatch();

  const [tnLabel, setTnLabel] = useState(
    filters[0].values.find((v) => v.name === currentTn).alias
  );
  const [trLabel, setTrLabel] = useState(
    filters[1].values.find((v) => v.name === currentTr).alias + ' años'
  );
  const [vs30Label, setVs30Label] = useState(
    filters[2].values.find((v) => v.name === currentVs30).alias
  );

  /**
   * Shows report on chosen point and hides modal window.
   * @function handleShowReport
   */
  const handleShowReport = () => {
    if (chosenLonLat.length) {
      setReportIsShown(true);
      setPopoverIsShown(false);
    }
  };

  /**
   * Sets map to initial bounding box and zoom level.
   * @function flyToInitPosistion
   */
  const flyToInitPosistion = () => {
    dispatch(setSettings({ flyToZoom: minZoom }));
    dispatch(setSettings({ flyToLatLon: initialMapCoords }));
  };

  /**
   * Sets loader to full if data is loading.
   * @function useEffect[loading]
   */
  useEffect(() => {
    document.getElementsByClassName('timecounter-item')[0].classList.add('firstload');
  }, [loading]);

  /**
   * Hides search results if report is shown.
   * @function useEffect[reportIsShown]
   */
  useEffect(() => {
    if (reportIsShown) {
      setShowSearchResults(false);
    }
  }, [reportIsShown]);

  /**
   * Changes Tn label if filters or current Tn were changed.
   * @function 'useEffect[currentTn, filters]'
   */
  useEffect(() => {
    const value = filters[0].values.find((v) => v.name === currentTn).alias;
    setTnLabel(value);
  }, [currentTn, filters]);

  /**
   * Changes Tr label if filters or current Tr were changed.
   * @function 'useEffect[currentTr, filters]'
   */
  useEffect(() => {
    const value = filters[1].values.find((v) => v.name === currentTr).alias + ' años';
    setTrLabel(value);
  }, [currentTr, filters]);

  /**
   * Changes Vs30 label if filters or current Vs30 were changed.
   * @function 'useEffect[currentVs30, filters]'
   */
  useEffect(() => {
    const value = filters[2].values.find((v) => v.name === currentVs30).alias;
    setVs30Label(value);
  }, [currentVs30, filters]);

  /**
   * Sets css transition property of mini map to 0 secs if it is not shown so when you hide it it could disappear instanlty without delay.
   * @function useEffect[miniMapIsShown]
   */
  useEffect(() => {
    if (miniMapIsShown) {
      document.getElementById('active-minimap').style.transition = '1s';
    } else {
      document.getElementById('active-minimap').style.transition = '0s';
    }
  }, [miniMapIsShown]);

  /**
   * Sets data range on app loading.
   * @function useEffect[]
   */
  useEffect(() => {
    dispatch(setDataRange());
  }, []);

  /**
   * Changes Time Counter loader to run/full depending on passed flag value.
   * @function changeLoaderClassList
   * @param {Boolean} flag
   */
  const changeLoaderClassList = (flag) => {
    if (loading) {
      document.getElementsByClassName('timecounter-item')[0].classList.add('firstload');
    } else {
      document
        .getElementsByClassName('timecounter-item')[0]
        .classList.remove('firstload');
    }

    if (!loading && flag) {
      document.getElementsByClassName('timecounter-item')[0].classList.add('counting');
    } else {
      document.getElementsByClassName('timecounter-item')[0].classList.remove('counting');
    }
  };

  /**
   * Makes HTML element dragable.
   * @function dragElement
   * @param {HTMLElement} element the element to drag
   * @param {HTMLElement} dragzone container for draggable element
   */
  const dragElement = (element, dragzone) => {
    let pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;
    //MouseUp occurs when the user releases the mouse button
    const dragMouseUp = () => {
      document.onmouseup = null;
      //onmousemove attribute fires when the pointer is moving while it is over an element.
      document.onmousemove = null;

      element.classList.remove('drag');
    };

    const dragMouseMove = (event) => {
      event.preventDefault();
      //clientX property returns the horizontal coordinate of the mouse pointer
      pos1 = pos3 - event.clientX;
      //clientY property returns the vertical coordinate of the mouse pointer
      pos2 = pos4 - event.clientY;
      pos3 = event.clientX;
      pos4 = event.clientY;
      if (element.offsetTop >= -10 && element.offsetTop <= 270) {
        element.style.top = `${element.offsetTop - pos2}px`;
        document.getElementById('active-minimap').style.top =
          initMiniMapFramePosition -
          (element.offsetTop - pos2) -
          activeMinimapPosition +
          'px';
        document.getElementById('active-minimap').style.transition = '0s';
      } else {
        if (element.offsetTop < -10) {
          element.style.top = '-10px';
        }
        if (element.offsetTop > 270) {
          element.style.top = '270px';
        }
      }
      //offsetTop property returns the top position relative to the parent

      // elementsDefaultFill();
      // dispatch(setSettings({ activeRegions: [...getElementId(element.offsetTop)] }));
    };

    const dragMouseDown = (event) => {
      // if (event.target.id === 'minimap-frame') {
      if (
        event.target.id === 'active-minimap-container' ||
        event.target.id === 'active-minimap'
      ) {
        event.preventDefault();

        pos3 = event.clientX;
        pos4 = event.clientY;

        element.classList.add('drag');

        document.onmouseup = dragMouseUp;
        document.onmousemove = dragMouseMove;
      } else {
        return;
      }
    };

    dragzone.onmousedown = dragMouseDown;
  };

  // useEffect(() => {
  //   if (!loading) {
  //     document.getElementsByClassName('timecounter-item')[0].classList.add('counting');
  //   }
  // }, [value]);

  /**
   * Sets drag zone and element for minimap.
   * @function useEffect[mapIsReady]
   */
  useEffect(() => {
    if (mapIsReady) {
      const dragable = document.getElementById('minimap-frame');
      const dragzone = document.getElementById('minimap-dragzone');

      dragElement(dragable, dragzone);
    }
  }, [mapIsReady]);

  return (
    <>
      <link
        href='https://fonts.googleapis.com/icon?family=Material+Icons'
        rel='stylesheet'
      ></link>
      <link
        rel='stylesheet'
        href='https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,1,200'
      />
      <ErrorComponent />
      <TimecounterContainer debouncedValue={debouncedValue} value={value} />
      <NavMenuContainer
        changeLoaderClassList={changeLoaderClassList}
        setValue={setValue}
        value={value}
        debouncedValue={debouncedValue}
        setPopoverIsShown={setPopoverIsShown}
        setFullFooterIsShown={setFullFooterIsShown}
        showSearchResults={showSearchResults}
        setShowSearchResults={setShowSearchResults}
      />
      <MapComponent
        setPopoverIsShown={setPopoverIsShown}
        changeLoaderClassList={changeLoaderClassList}
        setValue={setValue}
        value={value}
        debouncedValue={debouncedValue}
        popoverIsShown={popoverIsShown}
        zoomPercent={Math.ceil(((currentZoom - minZoom) / (maxZoom - minZoom)) * 100)}
        setFullFooterIsShown={setFullFooterIsShown}
        setShowSearchResults={setShowSearchResults}
        legendIsShown={legendIsShown}
        setLegendIsShown={setLegendIsShown}
        setminimapIsShown={setMiniMapIsShown}
        minimapIsShown={miniMapIsShown}
        flyToInitPosistion={flyToInitPosistion}
      />
      <ReportContainer
        setReportIsShown={setReportIsShown}
        reportIsShown={reportIsShown}
        tnLabel={tnLabel}
        trLabel={trLabel}
        vs30Label={vs30Label}
      />
      {chosenLonLat.length ? (
        <PopoverContainer
          currentTn={currentTn}
          currentTr={currentTr}
          handleShowReport={handleShowReport}
          popoverIsShown={popoverIsShown}
          setPopoverIsShown={setPopoverIsShown}
          chosenLonLat={chosenLonLat}
          vs30Label={vs30Label}
        />
      ) : null}
      <CurrentValuesComponent tnLabel={tnLabel} trLabel={trLabel} vs30Label={vs30Label} />
      <MiniMapContainer
        miniMapIsShown={miniMapIsShown}
        setMiniMapIsShown={setMiniMapIsShown}
        setValue={setValue}
        value={value}
      />
      <LegendComponent
        legendIsShown={legendIsShown}
        setLegendIsShown={setLegendIsShown}
      />
      <FooterContainer
        fullFooterIsShown={fullFooterIsShown}
        setFullFooterIsShown={setFullFooterIsShown}
        fullFooterTabIndex={fullFooterTabIndex}
        setFullFooterTabIndex={setFullFooterTabIndex}
      />
    </>
  );
};

export default Home;
