import React from 'react';
import { Spinner } from 'react-bootstrap';
import './index.scss';

/**
 * Spinner used in report when data is loading.
 *
 * @module
 *
 * @returns {React.ReactHTML}
 */
const SpinnerComponent = () => {
  return (
    <div id='spinner-global-container'>
      <Spinner animation='border' role='status' id='spinner-global'>
        <span className='visually-hidden'>Loading...</span>
      </Spinner>
    </div>
  );
};

export default SpinnerComponent;
