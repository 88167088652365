import {
  GET_INFO,
  INFO_IS_LOADING,
  INFO_LOADED,
  SET_TABLA,
  SET_G1,
  SET_G2,
  GET_SIGNATURE,
  GET_REPORT,
  SET_REPORT,
  GET_QID,
} from './types';
import { returnErrors } from '../error/actions';
import { setSettings } from '../settings/actions';
import axios from 'axios';
import {
  INFO_ENDPOINT,
  pinpoint_table_numeric_attributes,
  REPORT_ENDPOINT,
} from '../../constants';
import Papa from 'papaparse';
import store from '../store';
import { transformTablaData, transformChartsData } from '../../helpers';

/**
 * Stores data on report.
 * @module
 *
 * @property {String} INFO_ENDPOINT ../../constants -> info endpoint
 * @property {String[]} pinpoint_table_numeric_attributes ../../constants -> variable that save de json from info endpoint to put in report table
 * @property {String} REPORT_ENDPOINT ../../constants -> report endpoint
 */

/**
 * Sets data for table in report.
 * @function getTabla
 * @prop {Object} info Redux/info -> initial data for report
 * @prop {Object} tabla Redux/data -> processed data for table in report
 */
export const getTabla = () => (dispatch) => {
  const { info } = store.getState().info;
  const tabla = transformTablaData(info.data);

  dispatch({
    type: SET_TABLA,
    payload: tabla,
  });
};

/**
 * Process data from Redux/info/info and sets it to Redux/info/g1, Redux/info/g2
 * @function getCharts
 * @prop {Object} info Redux/info -> initial data for report
 */
export const getCharts = () => (dispatch) => {
  const { info } = store.getState().info;
  const { g1, g2 } = transformChartsData(info.data);

  dispatch({
    type: SET_G1,
    payload: g1,
  });
  dispatch({
    type: SET_G2,
    payload: g2,
  });
};

/**
 * Sets Redux/settings/report
 * @function setReport
 */
export const setReport = (state) => (dispatch) => {
  dispatch({
    type: SET_REPORT,
    payload: state,
  });
};

/**
 * Loads data from REPORT_ENDPOINT and sets Redux/info/report
 * @function getReport
 * @prop {String} qid Redux/info -> query id of the data of the report
 * @prop {Integer} currentVs30 Redux/settings -> current value of Vs30
 * @prop {Number[]} chosenLonLat Redux/settings -> longitude and latitude of a click on the map
 * @prop {String} url report endpoint url
 */
export const getReport = () => (dispatch) => {
  const { qid } = store.getState().info;
  const { currentVs30, chosenLonLat } = store.getState().settings;
  const url =
    REPORT_ENDPOINT +
    `?lon=${chosenLonLat[0]}&lat=${chosenLonLat[1]}&vs30=${currentVs30}&qid=${qid}`;

  // console.log('get report')
  axios.interceptors.response.use(undefined, (err) => {
    const { config, message } = err;
    if (!config || !config.retry) {
      return Promise.reject(err);
    }
    // retry while Network timeout or Network Error
    if (!(message.includes('timeout') || message.includes('Network Error'))) {
      return Promise.reject(err);
    }
    config.retry -= 1;
    const delayRetryRequest = new Promise((resolve) => {
      setTimeout(() => {
        console.log('retry the request', config.url);
        resolve();
      }, config.retryDelay || 1000);
    });
    return delayRetryRequest.then(() => axios(config));
  });

  axios
    .get(url, { retry: 3, retryDelay: 1000 })
    .then((res) => {
      dispatch({
        type: GET_REPORT,
        payload: res.data.file_pdf,
      });
    })
    .catch((e) => {
      console.log('ERROR: ', e);
      dispatch(returnErrors(e.message, e.code));
      dispatch({
        type: INFO_LOADED,
      });
    });
};

/**
 * Loads data from INFO_ENDPOINT and sets
 * Redux/info/infoIsLoading = true
 * sets response data to
 * Redux/info/qid
 * Redux/settings/nearestLonLat
 * Redux/info/signature
 * Redux/info/info
 * @function getInfo
 * @prop {Integer} currentVs30 Redux/settings ->  current vs30 selected for  the user
 * @prop {Number[]} chosenLonLat Redux/settings -> lat and long of the point into the map selected for the user
 * @prop {String} url info endpoint url
 */
export const getInfo = () => (dispatch) => {
  const { currentVs30, chosenLonLat } = store.getState().settings;
  const url = `${INFO_ENDPOINT}?lon=${chosenLonLat[0]}&lat=${chosenLonLat[1]}&vs30=${currentVs30}`;

  dispatch(setInfoIsLoading());
  axios.interceptors.response.use(undefined, (err) => {
    const { config, message } = err;
    if (!config || !config.retry) {
      return Promise.reject(err);
    }
    // retry while Network timeout or Network Error
    if (!(message.includes('timeout') || message.includes('Network Error'))) {
      return Promise.reject(err);
    }
    config.retry -= 1;
    const delayRetryRequest = new Promise((resolve) => {
      setTimeout(() => {
        console.log('retry the request', config.url);
        resolve();
      }, config.retryDelay || 1000);
    });
    return delayRetryRequest.then(() => axios(config));
  });

  axios
    .get(url, { retry: 3, retryDelay: 1000 })
    .then((res) => {
      dispatch({
        type: GET_QID,
        payload: res.data.qid,
      });
      Papa.parse(res.data.file_csv, {
        header: true,
        download: true,
        skipEmptyLines: true,
        complete: (result) => {
          pinpoint_table_numeric_attributes.forEach((att) => {
            result.data.forEach((el) => {
              if (el.hasOwnProperty(att)) el[att] = +el[att];
            });
          });

          dispatch(
            setSettings({
              nearestLonLat: [
                result.data[0].it_geo__longitud_i,
                result.data[0].it_geo__latitud_i,
              ],
            })
          );
          dispatch({
            type: GET_SIGNATURE,
            payload: res.data.signature,
          });

          dispatch({
            type: GET_INFO,
            payload: result,
          });
        },
        error: (err) => {
          console.log(err);
          dispatch(returnErrors('Error accessing csv file', ''));
          dispatch({
            type: INFO_LOADED,
          });
        },
      });
    })
    .catch((e) => {
      console.log('ERROR: ', e);
      dispatch(returnErrors(e.message, e.code));
      dispatch({
        type: INFO_LOADED,
      });
    });
};

/**
 * Sets Redux/info/infoIsLoading = true
 * @function setInfoIsLoading
 */
export const setInfoIsLoading = () => {
  return {
    type: INFO_IS_LOADING,
  };
};
