import {
  defaultTn,
  defaultTr,
  defaultVs30,
  filtersData,
  minSampleLimit,
  minZoom,
  flyToZoom,
} from '../../constants';
import { SET_DATA_RANGE, SET_MAX_DATA_RANGE, SET_SETTINGS } from './types';

/**
 * Stores settings.
 * @module
 *
 * @property {Number} defaultTn ../../constants -> Tn value chosen by default on app load
 * @property {Integer} defaultTr ../../constants -> Tr value chosen by default on app load
 * @property {Integer} defaultVs30 ../../constants -> Vs30 value chosen by default on app load
 * @property {Object[]} filtersData ../../constants -> data to display in left nav menu as filters, mapped from values and aliases
 * @property {Integer} minSampleLimit ../../constants -> not used anymore
 * @property {Integer} minZoom ../../constants -> min allowed zoom level
 * @property {Integer} flyToZoom ../../constants -> the level of zoom to apply flyTo function
 *
 * @property {Integer} currentZoom current zoom of the map value
 * @property {Integer} sampleLimit not used anymore
 * @property {Integer} currentVs30 current value of Vs30
 * @property {Number} currentTn current value of Tn
 * @property {Integer} currentTr current value of Tr
 * @property {Object[]} filters Tn/Tr/Vs30 filters data
 * @property {Boolean} mapIsReady [is map loaded and ready] ? true : false
 * @property {Integer[]} boundingBox min and max latitude and longitude of the current active map area
 * @property {Number[]} flyToLatLon latitude and longitude of the map point to fly to
 * @property {Number[]} chosenLonLat longitude and latitude of a click on the map
 * @property {Number[]} nearestLonLat longitude and latitude of the nearest observed point to the point clicked on the map
 * @property {Integer[]} chosenXY coordinates of click made inside map container, px
 * @property {Number[]} dataRange chosen by user min and max values on the scale for two types of data
 * @property {Number[]} maxDataRange available min and max values on the scale for two types of data
 * @property {Boolean} PointLayerIsOn [is points layer shown on the map] ? true : false
 * @property {Number[]} boundingBoxFromMiniMap bounding box from mini map in geo coordinates to put map into
 * @property {String} pickedMapValue value of a clicked hexbin
 * @property {Object} mapCenter current latitude and longitude of the center of the map
 * @property {Boolean} activeRegions not used anymore
 * @property {Integer} flyToZoom the level of zoom to apply flyTo function
 */

const initialState = {
  currentZoom: minZoom,
  sampleLimit: minSampleLimit,
  currentVs30: defaultVs30,
  currentTn: defaultTn,
  currentTr: defaultTr,
  filters: filtersData,
  mapIsReady: false,
  boundingBox: [], // lngmin, lngmax,latmin,latmax
  flyToLatLon: [],
  chosenLonLat: [],
  nearestLonLat: [],
  chosenXY: [],
  dataRange: [],
  maxDataRange: [],
  PointLayerIsOn: false,
  boundingBoxFromMiniMap: [],
  pickedMapValue: null,
  mapCenter: {},
  activeRegions: [],
  flyToZoom: flyToZoom,
};

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SETTINGS:
      return {
        ...state,
        ...action.payload,
      };

    case SET_DATA_RANGE:
      return {
        ...state,
        dataRange: action.payload.map((value, i) =>
          value === undefined ? state.dataRange[i] : value
        ),
      };

    case SET_MAX_DATA_RANGE:
      return {
        ...state,
        maxDataRange: action.payload,
      };

    default:
      return state;
  }
}
