import React, { useEffect, useRef, useState } from 'react';
import { CloseButton, Modal, Offcanvas, Accordion, Spinner, Card } from 'react-bootstrap';
import Charts from './charts/charts';
import RTable from './table/table';
import styles from './index.module.scss';
import { SpinnerComponent } from '../../components';
import useCopyToClipboard from './hooks/clipboard.js';
import { termsAndConds } from '../../constants';
import content from '../../lang/es.json';

/**
 * Comment here
 *
 * @module
 *
 * @property {Number} tn ../../containers/report -> current value of Tn
 * @property {Integer} tr ../../containers/report -> current value of Tr
 * @property {Object} tabla ../../containers/report -> current data of the table
 * @property {Object[]} g1 ../../containers/report -> current data of the  left chart
 * @property {Object[]} g2 ../../containers/report -> current data of the right chart
 * @property {Boolean} reportIsShown ../../containers/report -> [is report shown] ? true : false
 * @property {Function} setReportIsShown ../../containers/report -> sets reportIsShown value
 * @property {Number[]} nearestLonLat ../../containers/report -> longitude and latitude of the nearest observed point to the point clicked on the map
 * @property {Boolean} infoIsLoading ../../containers/report -> [is info loading] ? true : false
 * @property {String} signature ../../containers/report -> report signature string
 * @property {String} report ../../containers/report ->  pdf link of the report
 * @property {String} vs30Label ../../containers/report -> current Vs30 value label
 * @property {Boolean} isAcceptConditions ../../containers/report -> [are conditions accepted by the user] ? true : false
 * @property {Function} setIsAcceptConditions ../../containers/report -> sets isAcceptConditions value
 * @property {Integer} currentVs30 ../../containers/report -> current value of Vs30
 *
 * @property {Boolean} smShow state that control de visibility of the modal of the clipboard message
 * @property {String} ekeyalcance state that control the if the alcance  accordion is open or close
 * @property {String} ekeyintro state that control the if the intro  accordion is open or close
 * @property {String} ekeyresultados state that control the if the resultados  accordion is open or close
 * @property {String} ekeysupuestos state that control the if the supuestos  accordion is open or close
 * @property {String} ekeyrecon state that control the if the reconocimientos  accordion is open or close
 * @property {String} ekeyref state that control the if the referencias  accordion is open or close
 * @property {Function} copy variable that save into clipboard the text selected
 *
 * @returns {React.ReactHTML}
 */

const ReportComponent = ({
  tn,
  tr,
  tabla,
  g1,
  g2,
  reportIsShown,
  setReportIsShown,
  nearestLonLat,
  infoIsLoading,
  signature,
  report,
  vs30Label,
  isAcceptConditions,
  setIsAcceptConditions,
  currentVs30,
}) => {
  const [smShow, setSmShow] = useState(false);
  const [ekeyalcance, setekeyalcance] = useState('1');
  const [ekeyintro, setekeyintro] = useState('1');
  const [ekeyresultados, setekeyresultados] = useState('0');
  const [ekeysupuestos, setekeysupuestos] = useState('1');
  const [ekeyrecon, setekeyrecon] = useState('1');
  const [ekeyref, setekeyref] = useState('1');
  const [value, copy] = useCopyToClipboard();

  // const [containerWidth, setContainerWidth] = useState();
  /**
   * Function that handle if the accordeons are opened or closed
   * @function handleAccordeon
   * @param {Integer} accordion  id of the accordeon selected
   */
  function handleAccordeon(accordion) {
    switch (accordion) {
      case 'alcance':
        ekeyalcance === '0' ? setekeyalcance('1') : setekeyalcance('0');
        break;

      case 'introduccion':
        ekeyintro === '0' ? setekeyintro('1') : setekeyintro('0');
        break;
      case 'resultados':
        ekeyresultados === '0' ? setekeyresultados('1') : setekeyresultados('0');
        break;

      case 'supuestos':
        ekeysupuestos === '0' ? setekeysupuestos('1') : setekeysupuestos('0');
        break;

      case 'reconocimiento':
        ekeyrecon === '0' ? setekeyrecon('1') : setekeyrecon('0');
        break;

      case 'referencias':
        ekeyref === '0' ? setekeyref('1') : setekeyref('0');
        break;

      default:
        return null;
    }
  }

  /**
   * Sets settings to default values.
   * @function resetsettings
   */
  const resetsettings = () => {
    setReportIsShown(false);
    setIsAcceptConditions(false);
    setekeyalcance('1');
    setekeyintro('1');
    setekeyresultados('0');
    setekeysupuestos('1');
    setekeyrecon('1');
    setekeyref('1');
  };

  /**
   * Toggles isAcceptConditions value.
   * @function handleAcceptCondition
   */
  const handleAcceptCondition = () => {
    setIsAcceptConditions(!isAcceptConditions);
  };

  return (
    <Offcanvas
      style={{ width: '100%' }}
      show={reportIsShown}
      onHide={() => setReportIsShown(false)}
    >
      <Offcanvas.Body style={{ padding: '0px' }}>
        {infoIsLoading ? (
          <SpinnerComponent />
        ) : (
          <div>
            <div className={styles['header']}>
              <CloseButton
                className={styles['close_button']}
                onClick={resetsettings}
              ></CloseButton>
              <div className={styles['header_section']}>
                <div className={styles['title_section']}>
                  <h1 className={styles['title']}>Reporte de Amenaza Sísmica</h1>
                </div>
                <div className={styles['download_section']}>
                  <div className={styles['download_form']}>
                    <input
                      type='checkbox'
                      name='termcondition'
                      value='termcondition'
                      checked={isAcceptConditions}
                      onChange={handleAcceptCondition}
                    />
                    <span>
                      {content.web.report.body.term_conditions}
                      <a href={termsAndConds} target={'__blank'}>
                        {content.web.report.body.term_conditions_link}
                      </a>{' '}
                    </span>
                  </div>
                  <a
                    className={
                      !isAcceptConditions || report === ''
                        ? styles['d_button_disable']
                        : styles['d_button']
                    }
                    id={`generate-report-button`}
                    href={report}
                  >
                    <div>
                      {isAcceptConditions && report === '' ? (
                        <Spinner animation='border' role='status' size='sm'></Spinner>
                      ) : (
                        content.web.report.body.button
                      )}
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div className={styles['report']}>
              <div className={styles['section_chip_clipboard']}>
                <div className={styles.web_latlon}>
                  <button className={styles['chip_dark']} disabled>
                    {content.web.report.body.chips.lat}={' '}
                    {nearestLonLat.length > 0 ? nearestLonLat[1].toFixed(3) : 0}
                  </button>
                  <button className={styles['chip_dark']} disabled>
                    {content.web.report.body.chips.lon}={' '}
                    {nearestLonLat.length > 0 ? nearestLonLat[0].toFixed(3) : 0}
                  </button>
                  <button className={styles['chip_dark']} disabled>
                    {currentVs30 === -1
                      ? 'Vs30 según mapa de suelo'
                      : 'Vs30= ' + vs30Label}
                  </button>
                </div>
                <div className={styles.clipboard}>
                  <div>
                    <p className={styles['clipboard-content-title']}>
                      <strong>{content.web.report.header.clipboard.title}</strong>
                    </p>
                    <p className={styles['clipboard-content']}>
                      {content.web.report.header.clipboard.text1}
                    </p>
                  </div>
                  <div
                    style={{ marginTop: '-20px', cursor: 'pointer' }}
                    onClick={() => {
                      copy(content.web.report.header.clipboard.text1);
                      setSmShow(true);
                      setTimeout(() => {
                        setSmShow(false);
                      }, 1000);
                    }}
                  >
                    <Modal
                      size='sm'
                      show={smShow}
                      onHide={() => setSmShow(false)}
                      aria-labelledby='example-modal-sizes-title-sm'
                    >
                      <Modal.Body className={styles.modal_clipboard}>
                        {content.web.report.header.clipboard.modal}
                      </Modal.Body>
                    </Modal>
                    <svg
                      width='19'
                      height='23'
                      viewBox='0 0 19 23'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M14 0.5H2C0.9 0.5 0 1.4 0 2.5V16.5H2V2.5H14V0.5ZM17 4.5H6C4.9 4.5 4 5.4 4 6.5V20.5C4 21.6 4.9 22.5 6 22.5H17C18.1 22.5 19 21.6 19 20.5V6.5C19 5.4 18.1 4.5 17 4.5ZM17 20.5H6V6.5H17V20.5Z'
                        fill='black'
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div className={styles['section_content']}>
                <Accordion defaultActiveKey='0' flush>
                  <Card style={{ border: 'none' }}>
                    <Card.Header
                      style={{
                        borderRadius: '0px',
                        border: 'none',
                        cursor: 'pointer',
                        backgroundColor: '#f1f2f5',
                      }}
                      onClick={() => handleAccordeon('alcance')}
                    >
                      <div className={styles['card-header']}>
                        <span className={styles['card-title']}>
                          {content.web.report.body.alcance.title}
                        </span>
                        <span id={styles['icon']} className='material-icons'>
                          {ekeyalcance === '0' ? 'expand_less' : 'expand_more'}
                        </span>
                      </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey={ekeyalcance}>
                      <Card.Body>{content.web.report.body.alcance.text}</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>

                <Accordion defaultActiveKey='0' flush>
                  <Card style={{ border: 'none' }}>
                    <Card.Header
                      style={{
                        borderRadius: '0px',
                        border: 'none',
                        cursor: 'pointer',
                        backgroundColor: '#f1f2f5',
                      }}
                      onClick={() => handleAccordeon('introduccion')}
                    >
                      <div className={styles['card-header']}>
                        <span className={styles['card-title']}>
                          {content.web.report.body.introduccion.title}
                        </span>
                        <span id={styles['icon']} className='material-icons'>
                          {ekeyintro === '0' ? 'expand_less' : 'expand_more'}
                        </span>
                      </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey={ekeyintro}>
                      <Card.Body>
                        <p className={styles.introduccion_p}>
                          {content.web.report.body.introduccion.text1}
                          <b>
                            {nearestLonLat.length > 0 ? nearestLonLat[1].toFixed(3) : 0}
                          </b>
                          ,{' '}
                          <b>
                            {nearestLonLat.length > 0 ? nearestLonLat[0].toFixed(3) : 0}
                          </b>{' '}
                          {currentVs30 === -1
                            ? content.web.report.body.introduccion.text2.slice(0, -3)
                            : content.web.report.body.introduccion.text2}
                          <b>
                            {currentVs30 === -1
                              ? 'según mapa de suelo'
                              : vs30Label[0]?.toLowerCase() + vs30Label.substring(1)}
                          </b>
                          {content.web.report.body.introduccion.text3}
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>

                <Accordion defaultActiveKey='0' flush>
                  <Card style={{ border: 'none' }}>
                    <Card.Header
                      style={{
                        borderRadius: '0px',
                        border: 'none',
                        cursor: 'pointer',
                        backgroundColor: '#f1f2f5',
                      }}
                      onClick={() => handleAccordeon('resultados')}
                    >
                      <div className={styles['card-header']}>
                        <span className={styles['card-title']}>
                          {content.web.report.body.resultados.title}
                        </span>
                        <span id={styles['icon']} className='material-icons'>
                          {ekeyresultados === '0' ? 'expand_less' : 'expand_more'}
                        </span>
                      </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey={ekeyresultados}>
                      <Card.Body>
                        {content.web.report.body.resultados.text}
                        <div className={styles['table_section']}>
                          {tabla && nearestLonLat.length && (
                            <RTable
                              tn={tn}
                              tr={tr}
                              vs30={vs30Label}
                              tabla={tabla}
                              nearestLonLat={nearestLonLat}
                            />
                          )}
                        </div>
                        <div className={styles['charts_section']}>
                          {g1 && g2 && (
                            <Charts
                              g1={g1}
                              g2={g2}
                              nearestLonLat={nearestLonLat}
                              // containerWidth={containerWidth}
                            />
                          )}
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>

                <Accordion defaultActiveKey='0' flush>
                  <Card style={{ border: 'none' }}>
                    <Card.Header
                      style={{
                        borderRadius: '0px',
                        border: 'none',
                        cursor: 'pointer',
                        backgroundColor: '#f1f2f5',
                      }}
                      onClick={() => handleAccordeon('supuestos')}
                    >
                      <div className={styles['card-header']}>
                        <span className={styles['card-title']}>
                          {content.web.report.body.supuestos.title}
                        </span>
                        <span id={styles['icon']} className='material-icons'>
                          {ekeysupuestos === '0' ? 'expand_less' : 'expand_more'}
                        </span>
                      </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey={ekeysupuestos}>
                      <Card.Body>
                        {content.web.report.body.supuestos.text.map((el) => {
                          return (
                            <p key={el}>
                              {el}
                              <br />
                            </p>
                          );
                        })}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>

                <Accordion defaultActiveKey='0' flush>
                  <Card style={{ border: 'none' }}>
                    <Card.Header
                      style={{
                        borderRadius: '0px',
                        border: 'none',
                        cursor: 'pointer',
                        backgroundColor: '#f1f2f5',
                      }}
                      onClick={() => handleAccordeon('reconocimiento')}
                    >
                      <div className={styles['card-header']}>
                        <span className={styles['card-title']}>
                          {content.web.report.body.reconocimientos.title}
                        </span>
                        <span id={styles['icon']} className='material-icons'>
                          {ekeyrecon === '0' ? 'expand_less' : 'expand_more'}
                        </span>
                      </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey={ekeyrecon}>
                      <Card.Body>
                        {content.web.report.body.reconocimientos.text.map((el) => {
                          return (
                            <p key={el}>
                              {el}
                              <br />
                            </p>
                          );
                        })}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>

                <Accordion defaultActiveKey='0' flush>
                  <Card style={{ border: 'none' }}>
                    <Card.Header
                      style={{
                        borderRadius: '0px',
                        border: 'none',
                        cursor: 'pointer',
                        backgroundColor: '#f1f2f5',
                      }}
                      onClick={() => handleAccordeon('referencias')}
                    >
                      <div className={styles['card-header']}>
                        <span className={styles['card-title']}>
                          {content.web.report.body.referencias.title}
                        </span>
                        <span id={styles['icon']} className='material-icons'>
                          {ekeyref === '0' ? 'expand_less' : 'expand_more'}
                        </span>
                      </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey={ekeyref}>
                      <Card.Body>
                        {content.web.report.body.referencias.text.map((el) => {
                          return (
                            <p key={el}>
                              {el}
                              <br />
                            </p>
                          );
                        })}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>

              <div className={styles['section_content']}></div>
            </div>
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ReportComponent;
