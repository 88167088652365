import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MiniMapComponent } from '../components';
import {
  miniMapMin,
  miniMapMax,
  chileMinLat,
  chileMaxLat,
  chileCenterPoints,
  miniMapFrameHeight,
  activeMinimapPosition,
  initMiniMapFramePosition,
} from '../constants';
import { setSettings } from '../redux';
import { elementsDefaultFill, getElementId } from '../helpers';

/**
 * Mini map navigation container.
 *
 * @module
 *
 * @property {Boolean} miniMapIsShown ../pages/home -> [is mini map shown] ? true : false
 * @property {Boolean} setMiniMapIsShown ../pages/home -> sets miniMapIsShown value
 * @property {Integer} value ../pages/home -> a value counting +1 when the user interacts with map/minimap, used for debouncing: when it stops counting the debouncing timer starts, when it counts the debouncing timer is reset
 * @property {Function} setValue ../pages/home -> sets the value of value
 *
 * @property {Object} mapCenter Redux/settings -> current latitude and longitude of the center of the map
 * @property {Boolean} mapIsReady Redux/settings -> [is map loaded and ready] ? true : false
 * @property {Boolean} activeRegions Redux/settings -> not used anymore
 *
 * @property {Integer} miniMapRange minimap height in pixels
 * @property {Number} chileGeoRange chile length in geo coords
 * @property {Number} pixelsToCoords geo coords to pixels of minimap ratio
 * @property {Number} coordsToPixels pixels of minimap to geo coords ratio
 * @property {ref} frameRef ref attached to the moving frame of the minimap
 *
 * @property {} miniMapMin ../constants -> mini map frame min position in container, px
 * @property {} miniMapMax ../constants -> mini map frame max position in container, px
 * @property {} chileMinLat ../constants -> Chile min latitude
 * @property {} chileMaxLat ../constants -> Chile max latitude
 * @property {} chileCenterPoints ../constants -> center points of Chile to position map horizontally when flyTo function is applied
 * @property {} miniMapFrameHeight ../constants -> mini map frame height, px
 * @property {} activeMinimapPosition ../constants -> corrects the position of the background image of minimap frame
 * @property {} initMiniMapFramePosition ../constants -> corrects the position of mini map frame
 *
 * @returns {React.ReactElement} MiniMapComponent
 */

const MiniMapContainer = ({ miniMapIsShown, setMiniMapIsShown, setValue, value }) => {
  const dispatch = useDispatch();
  const { mapCenter, mapIsReady, activeRegions } = useSelector((state) => state.settings);
  const miniMapRange = miniMapMax - miniMapMin;
  const chileGeoRange = chileMaxLat - chileMinLat;
  const pixelsToCoords = chileGeoRange / miniMapRange;
  const coordsToPixels = miniMapRange / chileGeoRange;

  const frameRef = useRef();

  /**
   * Not used anymore.
   * @function imgLeaveHandler
   */
  const imgLeaveHandler = (e) => {
    elementsDefaultFill(activeRegions);
  };

  /**
   * Not used anymore.
   * @function imgHoverHandler
   */
  const imgHoverHandler = (e) => {
    elementsDefaultFill(activeRegions);
    getElementId(e.nativeEvent.layerY);
  };

  /**
   * Moves the mnbinmap frame to a clicked location on the minimap, converts minimap location into geo coords and sets them to Redux/settings/flyToLatLon triggering flyTo function.
   * @function imgClickHandler
   * @param {HTMLElement} e image of mini map clicked by user
   * @param {Boolean} [dragged=false] [is user dragging the mninmap frame] ? true : false
   */
  const imgClickHandler = (e, dragged = false) => {
    let yToConvert;

    // elementsDefaultFill();

    if (dragged) {
      yToConvert = document.getElementById('minimap-frame').offsetTop + 20;
    } else {
      e.stopPropagation();
      yToConvert = e.nativeEvent.layerY;
    }

    let convertedCoord = chileMaxLat - pixelsToCoords * yToConvert;

    const lat = findClosestPoint(convertedCoord)[0];

    dispatch(setSettings({ flyToLatLon: [convertedCoord, lat] }));

    if (yToConvert >= miniMapRange - (miniMapFrameHeight / 4) * 3) {
      yToConvert = miniMapRange - (miniMapFrameHeight / 4) * 3;
    }
    if (yToConvert <= (miniMapFrameHeight / 4) * 3) {
      yToConvert = -(miniMapFrameHeight / 4);
    }
    if (
      yToConvert < miniMapRange - (miniMapFrameHeight / 4) * 3 &&
      yToConvert > (miniMapFrameHeight / 4) * 3
    ) {
      yToConvert = yToConvert - miniMapFrameHeight / 2;
    }

    document.getElementById('minimap-frame').style.top = yToConvert + 'px';

    document.getElementById('active-minimap').style.top =
      initMiniMapFramePosition - yToConvert - activeMinimapPosition + 'px';
    document.getElementById('active-minimap').style.transition = '1s';
  };

  /**
   * If map was moved/zoomed moves frame of the minimap.
   * @function useEffect[mapCenter]
   */
  useEffect(() => {
    const yToConvert = mapCenter.lat - chileMaxLat;
    let convertedCoord = -coordsToPixels * yToConvert - miniMapFrameHeight / 2;

    if (convertedCoord < miniMapFrameHeight / 4) {
      convertedCoord = -(miniMapFrameHeight / 4);
    }
    if (convertedCoord >= miniMapRange - (miniMapFrameHeight / 4) * 3) {
      convertedCoord = miniMapRange - (miniMapFrameHeight / 4) * 3;
    }

    document.getElementById('minimap-frame').style.top = convertedCoord + 'px';

    document.getElementById('active-minimap').style.top =
      initMiniMapFramePosition - convertedCoord - activeMinimapPosition + 'px';
    document.getElementById('active-minimap').style.transition = '1s';

    // if (value > 0) {
    //   setValue(value + 1);
    // }

    // console.log('center set');
    // if (mapIsReady) {
    //   elementsDefaultFill();
    //   dispatch(setSettings({ activeRegions: [...getElementId(convertedCoord)] }));
    // }
  }, [mapCenter]);

  /**
   * Finds the closest point in the array of chile central line to position map horizontally.
   * @function findClosestPoint
   * @param {Number} num vertical geo coordinate
   */
  const findClosestPoint = (num) => {
    var curr = [...chileCenterPoints[0]];

    for (let i = 1; i < chileCenterPoints.length; i++) {
      if (Math.abs(chileCenterPoints[i][1] - num) < Math.abs(curr[1] - num)) {
        curr = chileCenterPoints[i];
      }
    }

    return curr;
  };

  return (
    <MiniMapComponent
      imgClickHandler={imgClickHandler}
      miniMapIsShown={miniMapIsShown}
      setMiniMapIsShown={setMiniMapIsShown}
      imgHoverHandler={imgHoverHandler}
      imgLeaveHandler={imgLeaveHandler}
      frameRef={frameRef}
      setValue={setValue}
      value={value}
    />
  );
};

export default MiniMapContainer;
