import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';

/**
 * Current values widget. Takes Tn, Tr, Vs30 values but also stores them locally in state to rerender them in UI only after data loaded
 *
 * @module
 *
 * @property {String} tnLabel ../../pages/home -> current Tn value label
 * @property {String} trLabel ../../pages/home -> current Tr value label
 * @property {String} vs30Label ../../pages/home -> current Vs30 value label
 *
 * @property {Array.<Array.<Number>>} data Redux/data -> initial data loaded from endpoint
 * @property {Boolean} loading Redux/data -> [is data loading] ? true : false
 *
 * @returns {React.ReactHTML}
 */
const CurrentValuesComponent = ({ tnLabel, trLabel, vs30Label }) => {
  const [tn, SetTn] = useState(tnLabel);
  const [tr, SetTr] = useState(trLabel);
  const [vs30, SetVs30] = useState(vs30Label);

  const { data, loading } = useSelector((state) => state.data);

  /**
   * Updates Tn, Tr, Vs30 values in state when data was changed.
   * @function useEffect[data]
   */
  useEffect(() => {
    if (!loading) {
      SetTn(tnLabel);
      SetTr(trLabel);
      SetVs30(vs30Label);
    }
  }, [data]);

  return (
    <div className={styles['currentvalues']}>
      <div className={styles['row']}>
        <div className={styles['tuneicon']}>
          <span className='material-icons'>tune</span>
        </div>
        <div>{tn}</div>
        <div className={styles['middle']}>{tr}</div>
        <div>{vs30}</div>
      </div>
    </div>
  );
};

export default CurrentValuesComponent;
