import React from 'react';
import content from '../../lang/es.json';
import {
  Form,
  OverlayTrigger,
  Popover,
  InputGroup,
  Spinner,
  ListGroup,
} from 'react-bootstrap';
import styles from './index.module.scss';
import './bootstrap.scss';
import { useSelector } from 'react-redux';
import { LogoComponent } from '../../components';

/**
 * Renders nav menu component.
 *
 * @module
 *
 * @property {Function} handleFilterSwitchMode ../../containers/navmenu -> sets Redux/settings/filter according to currently chosen Tn/Tr/Vs30
 * @property {Function} setShowSearchResults ../../containers/navmenu -> sets showSearchResults value
 * @property {object[]} searchResults ../../containers/navmenu -> search results from endpoint
 * @property {Function} handleSearchResultClick ../../containers/navmenu -> when one of search results was clicked it hides search results and sets lat, lon in Redux/settings to fly to which triggers the flyTo function
 * @property {String} searchValue ../../containers/navmenu -> the string typed in the search field
 * @property {Function} handleSearchField ../../containers/navmenu -> sets searchValue with a value from search field
 * @property {Function} handleMapSearch ../../containers/navmenu -> sends a search request to search endpoint
 * @property {Boolean} searching ../../containers/navmenu -> [waiting for a response from search endpoint] ? true : false
 *
 * @returns {React.ReactHTML}
 */
const NavMenuComponent = ({
  handleFilterSwitchMode,
  showSearchResults,
  searchResults,
  handleSearchResultClick,
  searchValue,
  handleSearchField,
  handleMapSearch,
  searching,
}) => {
  const { filters } = useSelector((state) => state.settings);

  return (
    <div className={styles['navmenu']}>
      <div className={styles['navmenu_logo']}>
        <LogoComponent />
        <span>v1.0.0</span>
      </div>
      <div className={styles['navmenu_abstract']}>
        <h3 className={styles['navmenu_abstract_title']}>
          {content.web.navmenu.abstract.title}
        </h3>
        <p className={styles['navmenu_abstract_text']}>
          {content.web.navmenu.abstract.text}
        </p>
      </div>
      <div className={styles['navmenu_search']}>
        <InputGroup className='navmenu-element'>
          <OverlayTrigger
            show={showSearchResults}
            placement={'bottom'}
            overlay={
              <Popover id={styles['search-results-container']}>
                <Popover.Body>
                  <ListGroup className={styles['list_group']}>
                    {!searchResults.length
                      ? 'No se encontró ningún resultado'
                      : searchResults.map((el) => {
                          return (
                            <ListGroup.Item
                              key={el.place_id}
                              action
                              onClick={(e) => handleSearchResultClick(e, el.lat, el.lon)}
                            >
                              <div className={styles['list_info']}>
                                <span
                                  id={styles['mapicon']}
                                  className='material-icons md-18'
                                >
                                  location_on
                                </span>
                                <p>{el.display_name}</p>
                              </div>
                            </ListGroup.Item>
                          );
                        })}
                  </ListGroup>
                </Popover.Body>
              </Popover>
            }
          >
            <Form.Control
              placeholder={content.web.navmenu.search.placeholder}
              value={searchValue}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleMapSearch();
                }
              }}
              aria-label="Recipient's username with two button addons"
              onChange={(e) => handleSearchField(e)}
            />
          </OverlayTrigger>

          <button
            disabled={searchValue.length < 4 || searching}
            onClick={() => handleMapSearch()}
            className={`${styles['navmenu-button']} ${styles['search-button']}`}
          >
            {searching ? (
              <Spinner animation='border' role='status' size='sm'>
                <span className='visually-hidden'>Loading...</span>
              </Spinner>
            ) : (
              <span className='material-icons'>search</span>
            )}
          </button>
        </InputGroup>
      </div>

      <div className={styles['navmenu_filters']}>
        {filters.length ? (
          <>
            {filters.map((el, ind) => {
              return (
                <div className={styles['navmenu_filters_filter']} key={'container' + ind}>
                  <div className={styles['navmenu_filters_filter_header']}>
                    <p
                      className={styles['navmenu_filters_filter_header_title']}
                      id={`button-${el.id}`}
                    >
                      {content.web.navmenu.filters[el.id].name}
                    </p>
                    <div className={styles['navmenu_filters_filter_header_tooltip']}>
                      <OverlayTrigger
                        placement={'right'}
                        overlay={
                          <Popover id='popover-contained'>
                            <Popover.Body>
                              <div className={styles['tooltip_body']}>
                                <strong>
                                  {content.web.navmenu.filters[el.id].name}:
                                </strong>
                                <p>{content.web.navmenu.filters[el.id].tooltip}</p>
                              </div>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <span id={styles['info_icon']} className='material-icons'>
                          info
                        </span>
                      </OverlayTrigger>
                    </div>
                  </div>
                  <div>
                    <div
                      className={styles['navmenu_filters_filter_chips']}
                      id={`popover-${el.id}`}
                    >
                      {el.values.map((subEl, subInd) => {
                        return (
                          <button
                            className={
                              subEl.value
                                ? styles['navmenu_filters_filter_chips_chip_dark']
                                : styles['navmenu_filters_filter_chips_chip']
                            }
                            disabled={subEl.name === 'Hexbins' ? true : false}
                            key={subEl.name}
                            id={`switch-${el.name}-${subEl.name}`}
                            onClick={(e) => handleFilterSwitchMode(e, ind, subInd)}
                          >
                            {subEl.alias}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <Spinner animation='border' role='status'>
            <span className={styles['visually-hidden']}>Loading...</span>
          </Spinner>
        )}
      </div>
    </div>
  );
};

export default NavMenuComponent;
