import React from 'react';
import './index.scss';
import { useSelector, useDispatch } from 'react-redux';
import { clearErrors } from '../../redux';
import { Button, Modal } from 'react-bootstrap';

/**
 * Renders error modal window component.
 *
 * @module
 *
 * @property {String} msg Redux/errors -> catched error message but it is used like a true/false flag to show error modal window
 *
 * @returns {React.ReactHTML}
 */
const ErrorComponent = () => {
  const { msg } = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  /**
   * When close button of error modal window is clicked clears errors in Redux store.
   * @function handleClose
   */
  const handleClose = () => dispatch(clearErrors());

  return (
    <Modal show={msg} centered onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Something went wrong</Modal.Title>
      </Modal.Header>
      <Modal.Body>Please reload the page</Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => handleClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorComponent;
