import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from './pages';
import { withErrorBoundary, useErrorBoundary } from 'react-use-error-boundary';
import { ErrorComponent } from './components';
import { useDispatch } from 'react-redux';
import { returnErrors } from './redux';

/**
 * Root component.
 *
 * @module
 *
 * @returns home page, and others if exist
 */

const App = withErrorBoundary(() => {
  const dispatch = useDispatch();

  /**
   * Hook that catches error and sets them to Redux store.
   * @function
   * @param {Object} error error instance
   */
  const [error, resetError] = useErrorBoundary(
    // You can optionally log the error to an error reporting service
    // (error, errorInfo) => console.log(error, errorInfo)
    (error, errorInfo) => dispatch(returnErrors(error, ''))
  );

  if (error) {
    return <ErrorComponent />;
  }

  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Home />} />
      </Routes>
    </Router>
  );
});

export default App;
