import React, { useState } from 'react';
import { ReportComponent } from '../components';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTabla, getCharts, getInfo, getReport, setReport } from '../redux';

/**
 * Report on chosen point on the map container.
 *
 * @module
 *
 * @property {Boolean} reportIsShown ../pages/home -> [is report shown] ? true : false
 * @property {Function} reportIsShown ../pages/home -> sets reportIsShown value
 * @property {String} tnLabel ../pages/home -> current Tn value label
 * @property {String} trLabel ../pages/home -> current Tr value label
 * @property {String} vs30Label ../pages/home -> current Vs30 value label
 *
 * @property {Number[]} chosenLonLat Redux/settings -> longitude and latitude of a click on the map
 * @property {Number[]} nearestLonLat Redux/settings -> longitude and latitude of the nearest observed point to the point clicked on the map
 * @property {Number} currentTn Redux/settings -> current value of Tn
 * @property {Integer} currentTr Redux/settings -> current value of Tr
 * @property {Integer} currentVs30 Redux/settings -> current value of Vs30
 *
 * @property {Boolean} isAcceptConditions [are conditions accepted by the user] ? true : false
 *
 * @property {Object} info Redux/info -> object tht have the info used in report
 * @property {Object} tabla Redux/info -> object that have the processed info used in the table
 * @property {Object[]} g1 Redux/info -> object that have the porcessed info used in the left chart
 * @property {Object[]} g2 Redux/info -> object that have the porcessed info used in the right chart
 * @property {Boolean} infoIsLoading Redux/info -> [is info loading] ? true : false
 * @property {String} signature Redux/info -> report signature string
 * @property {String} report Redux/info -> report pdf link
 *
 * @returns {React.ReactElement} ReportComponent.
 */

const ReportContainer = ({
  reportIsShown,
  setReportIsShown,
  tnLabel,
  trLabel,
  vs30Label,
}) => {
  const dispatch = useDispatch();
  const { chosenLonLat, nearestLonLat, currentTr, currentTn, currentVs30 } = useSelector(
    (state) => state.settings
  );
  const { info, tabla, g1, g2, infoIsLoading, signature, report } = useSelector(
    (state) => state.info
  );
  const [isAcceptConditions, setIsAcceptConditions] = useState(false);

  /**
   * Triggers loading info if the user chose a point on the map and opened report.
   * @function useEffect[reportIsShown]
   */
  useEffect(() => {
    if (reportIsShown && chosenLonLat.length) {
      dispatch(getInfo());
    }
  }, [reportIsShown]);

  /**
   * If info was loaded triggers loading Tabla and Charts data.
   * @function useEffect[info]
   */
  useEffect(() => {
    if (info.data) {
      dispatch(getTabla());
      dispatch(getCharts());
    }
  }, [info]);

  /**
   * If Conditions were accepted triggers report loading.
   * @function useEffect[isAcceptConditions]
   */
  useEffect(() => {
    dispatch(setReport({ report: '' }));
    if (isAcceptConditions) {
      dispatch(getReport());
    }
  }, [isAcceptConditions]);

  return (
    <ReportComponent
      data={info}
      report={report}
      tn={currentTn}
      tr={currentTr}
      tabla={tabla}
      g1={g1}
      g2={g2}
      reportIsShown={reportIsShown}
      setReportIsShown={setReportIsShown}
      nearestLonLat={nearestLonLat}
      infoIsLoading={infoIsLoading}
      signature={signature}
      tnLabel={tnLabel}
      trLabel={trLabel}
      vs30Label={vs30Label}
      isAcceptConditions={isAcceptConditions}
      setIsAcceptConditions={setIsAcceptConditions}
      currentVs30={currentVs30}
    />
  );
};

export default ReportContainer;
