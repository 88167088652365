import { GET_ERRORS, CLEAR_ERRORS } from './types';

/**
 * Stores errors.
 * @module
 */

/**
 *
 * @function returnErrors
 * @param {String} msg error message
 * @param {String} code error code
 * @param {String} [id=null] error id
 */
export const returnErrors = (msg, code, id = null) => {
  return {
    type: GET_ERRORS,
    payload: { msg, code, id },
  };
};

/**
 * Sets all props in Redux/errors to default values
 * @function clearErrors
 */
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
