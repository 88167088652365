export const GET_INFO = 'GET_INFO';
export const INFO_IS_LOADING = 'INFO_IS_LOADING';
export const INFO_LOADED = 'INFO_LOADED';
export const SET_TABLA = 'SET_TABLA';
export const SET_G1 = 'SET_G1';
export const SET_G2 = 'SET_G2';
export const GET_SIGNATURE = 'GET_SIGNATURE';
export const GET_REPORT = 'GET_REPORT'
export const GET_QID = 'GET_QID'
export const SET_REPORT = 'SET_REPORT'
