import React from 'react';
import './index.scss';
import { MapContainer, TileLayer } from 'react-leaflet';
import { Map } from '../../containers';
import { initialMapCoords, minZoom, maxZoom } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { setSettings } from '../../redux';
import content from '../../lang/es.json';

/**
 * Wraps Map into Leaflet Map Container so it is possible to use useMap hook.
 *
 * @module
 *
 * @property {Function} setPopoverIsShown ../../pages/home -> sets popoverIsShown value
 * @property {Function} changeLoaderClassList ../../pages/home -> changes Time Counter loader to run/full depending on passed flag valu
 * @property {Integer} value ../../pages/home -> a value counting +1 when the user interacts with map/minimap, used for debouncing: when it stops counting the debouncing timer starts, when it counts the debouncing timer is reset
 * @property {Function} setValue ../../pages/home -> sets value value
 * @property {Integer} debouncedValue ../../pages/home -> this value changes to value after debouncing timeout
 * @property {Integer} zoomPercent ../../pages/home -> current zoom level in percent to max allowed
 * @property {Boolean} popoverIsShown ../../pages/home -> [is modal window on right click on map shown] ? true : false
 * @property {Function} setFullFooterIsShown ../../pages/home -> sets fullFooterIsShown value
 * @property {Function} setShowSearchResults ../../pages/home -> sets showSearchResults value
 * @property {Boolean} legendIsShown ../../pages/home -> [is intensity scale shown] ? true : false
 * @property {Function} setLegendIsShown ../../pages/home -> sets legendIsShown value
 * @property {Function} setminiMapIsShown ../../pages/home -> sets miniMapIsShown value
 * @property {Boolean} miniMapIsShown ../../pages/home -> [is mini map shown] ? true : false
 * @property {Function} flyToInitPosistion ../../pages/home -> sets map to initial bounding box and zoom level
 *
 * @property {Number[]} initialMapCoords ../../constants -> initial map position geo coords
 * @property {Integer} minZoom ../../constants -> min allowed zoom level
 * @property {Integer} maxZoom ../../constants -> max allowed zoom level
 *
 * @returns {React.ReactComponentElement}
 */

const MapComponent = ({
  setPopoverIsShown,
  changeLoaderClassList,
  setValue,
  value,
  debouncedValue,
  zoomPercent,
  popoverIsShown,
  setFullFooterIsShown,
  setShowSearchResults,
  legendIsShown,
  setLegendIsShown,
  setminimapIsShown,
  minimapIsShown,
  flyToInitPosistion,
}) => {
  const dispatch = useDispatch();
  const { PointLayerIsOn } = useSelector((state) => state.settings);

  return (
    <>
      <link
        rel='stylesheet'
        href='https://unpkg.com/leaflet@1.9.3/dist/leaflet.css'
        integrity='sha256-kLaT2GOSpHechhsozzB+flnD+zUyjE2LlfWPgU04xyI='
        crossOrigin=''
      />

      <div className='zoom-percent' onClick={() => null}>
        {zoomPercent + '%'}
      </div>

      <div className={'button-container'}>
        <div
          className={
            minimapIsShown
              ? 'map-show-minimap-button-dark no-user-select'
              : 'map-show-minimap-button no-user-select'
          }
          onClick={() => {
            setminimapIsShown(!minimapIsShown);
            setFullFooterIsShown(false);
          }}
        >
          <div className='show-minimap-button'>
            <span id='icon' className='material-icons'>
              map
            </span>
          </div>
          <span>{content.web.widgets.minimap.title}</span>
        </div>
        <div
          className={
            legendIsShown
              ? 'map-show-legend-button-dark no-user-select'
              : 'map-show-legend-button no-user-select'
          }
          onClick={() => {
            setLegendIsShown(!legendIsShown);
            setFullFooterIsShown(false);
          }}
        >
          <div className='show-legend-button'>
            <span id='icon' className='material-icons'>
              opacity
            </span>
          </div>
          <span>{content.web.widgets.intensidad.title}</span>
        </div>

        <div
          className={
            PointLayerIsOn
              ? 'map-show-points-button-dark no-user-select'
              : 'map-show-points-button no-user-select'
          }
          onClick={() => {
            dispatch(setSettings({ PointLayerIsOn: !PointLayerIsOn }));
            setFullFooterIsShown(false);
          }}
        >
          <div className='show-points-button'>
            <span id='icon' className='material-icons'>
              grain
            </span>
          </div>
          <span>{content.web.widgets.puntos.title}</span>
        </div>

        <div className='map-center-map-button' onClick={() => flyToInitPosistion()}>
          <div className='show-points-button'>
            <span id='icon-location' className='material-symbols-outlined'>
              my_location
            </span>
          </div>
        </div>
      </div>

      <MapContainer
        center={initialMapCoords}
        zoom={minZoom}
        scrollWheelZoom={true}
        zoomControl={false}
        minZoom={minZoom}
        maxZoom={maxZoom}
      >
        <TileLayer
          attribution='&copy; <a href="https://esri.maps.arcgis.com/">Esri</a>'
          url='https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}'
        />
        <TileLayer url='https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Reference/MapServer/tile/{z}/{y}/{x}' />
        <Map
          setPopoverIsShown={setPopoverIsShown}
          changeLoaderClassList={changeLoaderClassList}
          setValue={setValue}
          value={value}
          debouncedValue={debouncedValue}
          popoverIsShown={popoverIsShown}
          setFullFooterIsShown={setFullFooterIsShown}
          setShowSearchResults={setShowSearchResults}
        />
      </MapContainer>
    </>
  );
};

export default MapComponent;
