import React from 'react';
import { ErrorComponent } from '../components';

/**
 * Catches error in app to show the message to user.
 *
 * @module
 *
 * @property {Boolean} showModal [is modal error window shown] ? true : false
 *
 * @returns {React.ReactElement} FoooterComponent
 */
class ErrorContainer extends React.Component {
  state = {
    showModal: false,
  };

  /**
   * Hides modal error wimdow.
   * @method
   *
   * @property {Object} error not used
   *
   * @returns {Object} { showModal: true }
   */
  static getDerivedStateFromError(error) {
    return { showModal: true };
  }

  /**
   * Hides modal error wimdow.
   * @method
   *
   * @returns {Object} { showModal: true }
   */
  static hideModal() {
    return { showModal: false };
  }

  /**
   * Logs catched error.
   * @function componentDidCatch
   *
   * @property {Object} error error instance
   * @property {Object} info error info instance
   */
  componentDidCatch(error, info) {
    this.logErrorToServices(error.toString(), info.componentStack);
  }

  /**
   * In this case logger function is just a console.log.
   * @function logErrorToServices
   */
  logErrorToServices = console.log;

  render() {
    if (this.state.showModal) {
      return (
        <ErrorComponent showModal={this.state.showModal} hideModal={this.hideModal} />
      );
    }
    return this.props.children;
  }
}

export default ErrorContainer;
