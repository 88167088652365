import React from 'react';
import logo from '../../icons/logo-color.svg';
import './index.scss';

/**
 * Renders Itrend logo.
 *
 * @module
 *
 * @returns {React.ReactHTML}
 */
const LogoComponent = () => {
  return (
    <a href='https://itrend.cl/' target={'__blank'}>
      <img src={logo} className='logo' alt='itrend-logo' />
    </a>
  );
};

export default LogoComponent;
