import { GET_DATA, FILTER_DATA, DATA_LOADED, DATA_LOADING, SET_GEOJSON } from './types';

/**
 * Stores initial data.
 * @module
 *
 * @property {Object[]} data initial data from endpoint
 * @property {Object[]} geoJson initial data converted to geoJson format
 * @property {Boolean} loading [is data loading] ? true : false
 */
const initialState = {
  data: [],
  geoJson: [],
  loading: false,
};

export default function dataReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DATA:
      return {
        ...state,
        data: action.payload,
        // loading: false,
      };

    case SET_GEOJSON:
      return {
        ...state,
        geoJson: action.payload,
        loading: false,
      };

    case FILTER_DATA:
      return {
        ...state,
        dataProcessed: action.payload.dataProcessed,
        loading: false,
      };

    case DATA_LOADED:
      return {
        ...state,
        loading: false,
      };

    case DATA_LOADING:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}
