import React, { useEffect, useState } from 'react';
import { PopoverComponent } from '../components';
import { useSelector } from 'react-redux';

/**
 * A popover opened by right click on the map container.
 *
 * @module
 *
 * @property {Function} handleShowReport ../pages/home -> shows report on chosen point and hides modal window
 * @property {Boolean} popoverIsShown ../pages/home -> [is modal window on right click on map shown] ? true : false
 * @property {Function} setPopoverIsShown ../pages/home -> sets popoverIsShown value
 * @property {Number[]} chosenLonLat ../pages/home -> longitude and latitude of a click on the map
 * @property {Integer} currentTn ../pages/home -> current value of Tn
 * @property {Integer} currentTr ../pages/home -> current value of Tr
 * @property {String} vs30Label ../pages/home -> current Vs30 value label
 *
 * @property {String} pickedMapValue Redux/settings -> value of a clicked hexbin
 * @property {Integer} currentVs30 Redux/settings -> current value of Vs30
 *
 * @property {Boolean} loading Redux/data -> [is data loading] ? true : false
 * @property {Array.<Array.<Number>>} data Redux/data -> initial data loaded from endpoint
 *
 * @property {Integer} tn current value of Tn, stored here locally to be changed only after data were loaded
 * @property {Integer} tr current value of Tr, stored here locally to be changed only after data were loaded
 * @property {Integer} vs30 current value of Vs30, stored here locally to be changed only after data were loaded
 * @property {String} vs30Lab ../pages/home -> current Vs30 value label, stored here locally to be changed only after data were loaded
 *
 * @returns {React.ReactElement} PopoverComponent
 */

const PopoverContainer = ({
  handleShowReport,
  popoverIsShown,
  setPopoverIsShown,
  chosenLonLat,
  currentTn,
  currentTr,
  vs30Label,
}) => {
  const { pickedMapValue, currentVs30 } = useSelector((state) => state.settings);
  const { loading, data } = useSelector((state) => state.data);

  const [tn, setTn] = useState(currentTn);
  const [tr, setTr] = useState(currentTr);
  const [vs30, setVs30] = useState(currentVs30);
  const [vs30Lab, setVs30Lab] = useState(currentVs30);

  /**
   * Changes Tn, Tr, Vs30, Vs30 label in the UI after data were loaded.
   * @function useEffect[data]
   */
  useEffect(() => {
    if (!loading) {
      setTn(currentTn);
      setTr(currentTr);
      setVs30(currentVs30);
      setVs30Lab(vs30Label);
      // setPopoverIsShown(false);
    }
  }, [data]);

  return (
    <PopoverComponent
      handleShowReport={handleShowReport}
      popoverIsShown={popoverIsShown}
      setPopoverIsShown={setPopoverIsShown}
      chosenLonLat={chosenLonLat}
      currentTn={tn}
      currentTr={tr}
      pickedMapValue={pickedMapValue}
      vs30Label={vs30Lab}
      currentVs30={vs30}
    />
  );
};

export default PopoverContainer;
