import React from 'react';
import './index.scss';
import { Toast } from 'react-bootstrap';
import content from '../../lang/es.json';

/**
 * Renders modal window on right click on map.
 *
 * @module
 *
 * @property {Function} handleShowReport ../../containers/popover -> shows report on chosen point and hides modal window
 * @property {Boolean} popoverIsShown ../../containers/popover -> [is modal window on right click on map shown] ? true : false
 * @property {Function} setPopoverIsShown ../../containers/popover -> sets popoverIsShown value
 * @property {Integer} currentTn ../../containers/popover -> current value of Tn
 * @property {Number[]} chosenLonLat ../../containers/popover -> longitude and latitude of a click on the map
 * @property {Integer} currentTr ../../containers/popover -> current value of Tr
 * @property {String} pickedMapValue ../../containers/popover -> value of a clicked hexbin
 * @property {String} vs30Label ../../containers/popover -> current Vs30 value label
 * @property {Integer} currentVs30 ../../containers/popover -> current value of Vs30
 *
 * @returns {React.ReactHTML}
 */
const PopoverComponent = ({
  handleShowReport,
  popoverIsShown,
  setPopoverIsShown,
  currentTn,
  chosenLonLat,
  currentTr,
  pickedMapValue,
  vs30Label,
  currentVs30,
}) => {
  return (
    <Toast
      show={popoverIsShown}
      onClose={() => setPopoverIsShown(false)}
      id='map-point-toast'
    >
      <Toast.Header className='toast_top'>
        <div className='w-100'></div>
      </Toast.Header>
      <Toast.Body className='toast_body'>
        <div className='toast_body_sa'>
          <div className='toast_body_sa_title'>
            {currentTn === -1
              ? content.web.widgets.resumen_point.PGV.title + ': '
              : currentTn === 0
              ? content.web.widgets.resumen_point.PGA.title + ': '
              : content.web.widgets.resumen_point.Sa.title + ': '}
            <span className='toast_body_sa_value'>
              {pickedMapValue + (currentTn === -1 ? ' [cm/s]' : ' g')}
            </span>
          </div>
        </div>
        <div className='toast_body_pointinfo'>
          <div className='toast_body_pointinfo_latlon'>
            <div className='toast_body_pointinfo_latlon_1'>
              <span className='material-icons md-18' id='popover-location-icon'>
                location_on
              </span>
              {'Lat.: ' + chosenLonLat[1].toFixed(5)}
            </div>
            <div className='toast_body_pointinfo_latlon_1'>
              <div style={{ width: '18px' }}></div>
              {' Lon.: ' + chosenLonLat[0].toFixed(5)}
            </div>
          </div>
          <div className='toast_body_pointinfo_filters'>
            <div className='toast_body_pointinfo_filters_1'>
              <span className='material-icons'>tune</span>
              {currentVs30 === -1 ? 'Vs30 según mapa de suelo' : 'Vs30=' + vs30Label}
            </div>
            <div className='toast_body_pointinfo_filters_1'>
              <div style={{ width: '24px' }}></div>
              {'Tr=' + currentTr} años
            </div>
          </div>
        </div>
        <div className='toast_body_disclaimer'>
          <p>
            {currentTn === -1
              ? content.web.widgets.resumen_point.PGV.description
              : currentTn === 0
              ? content.web.widgets.resumen_point.PGA.description
              : content.web.widgets.resumen_point.Sa.description}
          </p>
        </div>
        <hr style={{ width: '100%' }} />
        <div className='toast_footer'>
          <button
            className='toast_footer_button'
            variant='outline-secondary'
            id={`generate-report-button`}
            onClick={() => handleShowReport()}
          >
            {content.web.widgets.resumen_point.button}
          </button>
        </div>
      </Toast.Body>
    </Toast>
  );
};

export default PopoverComponent;
