import React, { useEffect, useState } from 'react';
import './index.scss';

/**
 * Renders time counter component.
 *
 * @module
 *
 * @property {Boolean} loading ../../containers/timecounter -> [is data loading] ? true : false
 * @property {Integer} debouncedValue ../../containers/timecounter -> this value changes to value after debouncing timeout
 * @property {Integer} value ../../containers/timecounter -> changing this value you reset debouncing timer
 *
 * @property {Boolean} blink2 a flag to set/unset blinking style to text in tiemcounter
 *
 * @returns {React.ReactHTML}
 */
const TimecounterComponent = ({ loading, debouncedValue, value }) => {
  const [blink2, setBlink2] = useState(false);
  // const [currentDebouncedValue, setCurrentDebouncedValue] = useState(debouncedValue);

  /**
   * When data was loaded sets blink2 to true on 3s to set text to blink.
   * @function useEffect[loading]
   */
  useEffect(() => {
    // console.log();
    if (!loading) {
      setBlink2(true);
      setTimeout(() => {
        setBlink2(false);
      }, 3000);
    }
  }, [loading]);

  return (
    <div className={'timecounter-container-extra'}>
      <div className='timecounter-container'>
        <div className='timecounter-item'>
          <span className='timecounter-item-text'>
            <div className='timecounter-item-text-content no-user-select'>
              {loading ? 'Cargando datos...' : null}
            </div>
          </span>
          <span>
            <div
              className={
                blink2
                  ? 'timecounter-item-text-content with-blinker no-user-select'
                  : 'timecounter-item-text-content no-user-select'
              }
            >
              {!loading && value === debouncedValue
                ? 'Click derecho sobre mapa para detalles...'
                : !loading && value !== debouncedValue
                ? 'Cargando datos...'
                : null}
            </div>
          </span>
        </div>

        {/* <span className='timecounter-item-text-black'>
          <div className='timecounter-item-text-black-content no-user-select'>
            Wait for new data to load...
          </div>
        </span> */}
      </div>
    </div>
  );
};

export default TimecounterComponent;
