import React from 'react';
import { TimecounterComponent } from '../components';
import { useSelector } from 'react-redux';

/**
 * A time to data loading counter container.
 *
 * @module
 *
 * @property {Integer} debouncedValue ../pages/home -> this value changes to value after debouncing timeout
 * @property {Integer} value ../pages/home -> changing this value you reset debouncing timer
 *
 * @property {Boolean} loading Redux/data -> [is data loading] ? true : false
 *
 * @returns {React.ReactElement} TimecounterComponent.
 */

const TimecounterContainer = ({ debouncedValue, value }) => {
  const { loading } = useSelector((state) => state.data);

  return (
    <TimecounterComponent
      loading={loading}
      debouncedValue={debouncedValue}
      value={value}
    />
  );
};

export default TimecounterContainer;
