import React from 'react';
import { FoooterComponent } from '../components';

/**
 * Footer container.
 *
 * @module
 *
 * @property {Boolean} fullFooterIsShown  ../pages/home -> [is full footer shown] ? true : false
 * @property {Function} setFullFooterIsShown ../pages/home -> sets fullFooterIsShown value
 * @property {Integer} fullFooterTabIndex ../pages/home -> full footer tab index
 * @property {Integer} setFullFooterTabIndex ../pages/home -> sets full footer tab index
 *
 * @returns {React.ReactElement} FoooterComponent
 */
const FooterContainer = ({
  fullFooterIsShown,
  setFullFooterIsShown,
  fullFooterTabIndex,
  setFullFooterTabIndex,
}) => {
  return (
    <FoooterComponent
      fullFooterIsShown={fullFooterIsShown}
      setFullFooterIsShown={setFullFooterIsShown}
      fullFooterTabIndex={fullFooterTabIndex}
      setFullFooterTabIndex={setFullFooterTabIndex}
    />
  );
};

export default FooterContainer;
