import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Lodash from 'lodash';
import { NavMenuComponent } from '../components';
import { getData, setSettings } from '../redux';

/**
 * Left navigation menu container.
 *
 * @module
 *
 * @property {Function} changeLoaderClassList ../pages/home -> changes Time Counter loader to run/full depending on passed flag value
 * @property {Integer} value ../pages/home -> a value counting +1 when the user interacts with map/minimap, used for debouncing: when it stops counting the debouncing timer starts, when it counts the debouncing timer is reset
 * @property {Function} setValue ../pages/home -> sets the value of value
 * @property {Integer} debouncedValue ../pages/home -> this value changes to value param after debouncing timeout
 * @property {Function} setPopoverIsShown ../pages/home -> sets popoverIsShown value
 * @property {Function} setFullFooterIsShown ../pages/home -> sets fullFooterIsShown value
 * @property {Boolean} showSearchResults ../pages/home -> [are search results shown] ? true : false
 * @property {Function} setShowSearchResults ../pages/home -> sets showSearchResults value
 *
 * @property {Integer} currentTn Redux/settings -> current value of Tn
 * @property {Integer} currentTr Redux/settings -> current value of Tr
 * @property {Integer} currentVs30 Redux/settings -> current value of Vs30
 * @property {Object[]} filters Redux/settings -> Tn/Tr/Vs30 filters data
 *
 * @property {String} searchValue the string typed in the search field
 * @property {object[]} searchResults search results from endpoint
 * @property {Boolean} searching [waiting for a response from search endpoint] ? true : false
 *
 * @returns {React.ReactElement} NavMenuComponent
 */

const NavMenuContainer = ({
  changeLoaderClassList,
  setValue,
  value,
  debouncedValue,
  setPopoverIsShown,
  setFullFooterIsShown,
  showSearchResults,
  setShowSearchResults,
}) => {
  const _ = Lodash;

  const dispatch = useDispatch();
  const { filters, currentTn, currentTr, currentVs30 } = useSelector(
    (state) => state.settings
  );

  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);

  /**
   * Sets Redux/settings/filter according to currently chosen Tn/Tr/Vs30.
   * @function handleFilterSwitchMode
   * @param {} e not used anymore
   * @param {Integer} ind index of the filter in Redux/settings/filter
   * @param {Integer} subInd index of the filter in Redux/settings/filter[ind]
   */
  const handleFilterSwitchMode = (e, ind, subInd) => {
    setFullFooterIsShown(false);
    // setPopoverIsShown(false);
    const checkedInd = checkIfOnlyOneChecked(ind);
    const arr = _.cloneDeep(filters);

    if (checkedInd === subInd) {
      return;
    } else {
      changeLoaderClassList(false);
      [...arr][ind].values.map((el) => (el.value = false));
    }

    dispatch(
      setSettings({
        filters: [...arr].map((el, i) =>
          i === ind
            ? {
                ...el,
                values: el.values.map((subEl, subI) =>
                  subI === subInd ? { ...subEl, value: !subEl.value } : { ...subEl }
                ),
              }
            : { ...el }
        ),
        currentTn: ind === 0 ? arr[0].values[subInd].name : currentTn,
        currentTr: ind === 1 ? arr[1].values[subInd].name : currentTr,
        currentVs30: ind === 2 ? arr[2].values[subInd].name : currentVs30,
      })
    );

    setTimeout(() => {
      changeLoaderClassList(true);
    }, 1);

    // setValue(value === 1 ? 2 : 1);
    setValue(value + 1);
  };

  /**
   * Triggers data loading from endpoint if debouncedValue was changed so debouncing time was out.
   * @function useEffect[debouncedValue]
   */
  useEffect(() => {
    if (debouncedValue) {
      dispatch(getData());
    }
  }, [debouncedValue]);

  /**
   * Checks if there is a chosen filter.
   * @function checkIfOnlyOneChecked
   * @param {Integer} ind index of the filter in Redux/settings/filter
   * @returns index of the chosen item in Redux/settings/filter[ind] or -1 if none chosen
   */
  const checkIfOnlyOneChecked = (ind) => {
    let count = filters[ind].values.filter((el) => el.value === true).length;
    if (count === 1) {
      let trueInd = filters[ind].values.findIndex((el) => el.value === true);
      return trueInd;
    } else {
      return -1;
    }
  };

  /**
   * Sets searchValue with a value from search field.
   * @function handleSearchField
   * @param {HTMLElement} e search field element
   */
  const handleSearchField = (e) => {
    setSearchValue(e.target.value);
  };

  /**
   * Sends a search request to search endpoint.
   * @function handleMapSearch
   */
  const handleMapSearch = () => {
    if (searchValue.length > 3) {
      setSearching(true);
      fetch(
        `https://nominatim.openstreetmap.org/search?q=${searchValue}&countrycodes=cl&format=json`
      )
        .then((response) => response.json())
        .then((data) => {
          setSearchResults(data);
          setShowSearchResults(true);
          setSearching(false);
        });
    }
  };

  /**
   * Hides search results if the user is typing to search field.
   * @function useEffect[searchValue]
   */
  useEffect(() => {
    if (searchValue.length <= 3) {
      setShowSearchResults(false);
    }
  }, [searchValue]);

  /**
   * When one of search results was clicked it hides search results and sets lat, lon in Redux/settings to fly to which triggers the flyTo function.
   * @function handleSearchResultClick
   * @param {} e not used anymore
   * @param {Number} lat latitude of click search result
   * @param {Number} lon longitude of click search result
   */
  const handleSearchResultClick = (e, lat, lon) => {
    setShowSearchResults(false);

    dispatch(setSettings({ flyToLatLon: [lat, lon] }));
  };

  return (
    <NavMenuComponent
      handleFilterSwitchMode={handleFilterSwitchMode}
      showSearchResults={showSearchResults}
      searchResults={searchResults}
      handleSearchResultClick={handleSearchResultClick}
      searchValue={searchValue}
      handleSearchField={handleSearchField}
      handleMapSearch={handleMapSearch}
      searching={searching}
    />
  );
};

export default NavMenuContainer;
